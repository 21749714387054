import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { delay, map, shareReplay, take, tap } from 'rxjs/operators';
import { StateAttributes, TripAttributes } from '~app/models';
import { LocationService } from '~app/services';

@Component({
  selector: 'ciao-trip-equipment-form',
  templateUrl: './trip-equipment-form.component.html',
  styleUrls: [
    './trip-equipment-form.component.less',
    '../trip-modal.component.less',
  ],
})
export class TripEquipmentFormComponent implements OnInit {
  @Input() trip: TripAttributes;
  @Input() equipmentId: string;
  @Input() mode: 'create' | 'update' | 'add' = 'add';
  @Input() openTab: string;

  @Output() formStatusChange = new EventEmitter<boolean>();
  isUpdate: boolean;

  tripEquipmentFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    details: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    license: new UntypedFormControl(''),
    make: new UntypedFormControl(''),
    model: new UntypedFormControl(''),
  });

  stateOptions$: Observable<{ value: StateAttributes; label: string }[]>;
  subscriptions: Subscription = new Subscription();

  constructor(private locationService: LocationService) {}

  ngOnInit(): void {
    this.setupOptions();
    this.isUpdate = this.mode === 'update';
    if (this.isUpdate && this.openTab === 'equipment') {
      this.populateEquipmentData();
    }
    this.setupFormStatusChangeEmission();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  compareStateForSelect(
    state1: StateAttributes,
    state2: StateAttributes
  ): boolean {
    return state1 && state2 && state1.id === state2.id;
  }

  findEquipmentById() {
    return this.trip.equipmentList.find((equipment) => {
      return equipment.id === this.equipmentId;
    });
  }

  public getFormData() {
    return this.tripEquipmentFormGroup;
  }

  populateEquipmentData(): void {
    const equipment = this.findEquipmentById();
    if (equipment) {
      this.stateOptions$
        .pipe(
          take(1), // take(1) so dont have to add to subscription destruction variable
          delay(0),
          tap(() => {
            this.tripEquipmentFormGroup.patchValue(equipment);
          })
        )
        .subscribe();
    }
  }

  setupFormStatusChangeEmission() {
    let formStatusSub = this.tripEquipmentFormGroup.statusChanges.subscribe(
      (status) => {
        this.formStatusChange.emit(status === 'VALID');
      }
    );

    this.subscriptions.add(formStatusSub);
  }

  setupOptions(): void {
    this.stateOptions$ = this.locationService.allStates$.pipe(
      map((states) =>
        states.map((state) => ({ value: state, label: state.stateCode }))
      ),
      shareReplay(1)
    );
  }
}
