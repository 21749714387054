<div class="container-fluid modal-form">
  <h3>{{ isUpdate ? "Update" : "Add" }} Location</h3>
  <div class="row">
    <ciao-form-field
      label="Description"
      type="textarea"
      placeholder="Address of location or any other notes to identify trip."
      [toolTip]="
        'This is a required field to update the status of your trip. Consult your agency\'s best practices to establish trip names that will be easily identifiable.'
      "
      [formControlInput]="tripLocationFormGroup.get('description')"
      class="col-lg-12"
    ></ciao-form-field>
  </div>

  <div class="row">
    <ciao-form-field
      class="col-lg-2 col-sm-12"
      label="State"
      type="combobox"
      [formControlInput]="tripLocationFormGroup.get('state')"
      [selectOptions$]="stateOptions$"
      [compareWith]="compareWithId"
    ></ciao-form-field>
    <ciao-form-field
      class="col-lg-7 col-sm-12"
      label="County"
      type="combobox"
      placeholder="Type in County"
      [formControlInput]="tripLocationFormGroup.get('county')"
      [selectOptions$]="countyOptions$"
    ></ciao-form-field>
    <ciao-form-field
      class="col-lg-3 col-sm-12"
      label="County Code"
      type="combobox"
      subtype="countyCodes"
      [formControlInput]="tripLocationFormGroup.get('countyCode')"
    ></ciao-form-field>
  </div>

  <div class="row">
    <ciao-form-field
      class="col-lg-4 col-sm-12"
      label="Plot Number"
      type="number"
      min="0"
      max="999999"
      step="1"
      [formControlInput]="tripLocationFormGroup.get('plotNumber')"
    ></ciao-form-field>
    <!-- <ciao-form-field
      class="col-lg-8 col-sm-12"
      label="LAT/LONG or GPS Coordinates"
      type="latlong"
      [formControlInput]="tripLocationFormGroup.get('coords')"
    ></ciao-form-field> -->
    <ciao-form-field
      class="col-lg-8 col-sm-12"
      label="LAT/LONG or GPS Coordinates"
      [toolTip]="
        'Possible Formats: Decimal degrees (DD): 40.748440, -73.984559 Degrees, Minutes, Seconds (DMS): 40 31 21 N, 105 5 39 W'
      "
      [formControlInput]="tripLocationFormGroup.get('textCoords')"
    ></ciao-form-field>
  </div>
</div>
