<div class="container-fluid modal-form">
  <h3>{{ isUpdate ? "Update" : "Add" }} Details</h3>
  <div class="custom-flex">
    <div class="status-group" *ngIf="trip?.tripStatus">
      <mat-label class="toolTipLabel">
        <div class="field-title">Trip Status</div>
        <ciao-tool-tip
          class="tool-tip"
          [title]="'Trip Status'"
          [message]="
            'Field will auto-populate dependent upon whether a user has created a trip, checked in, checked out or marked as okay.'
          "
        ></ciao-tool-tip>
      </mat-label>
      <div
        id="status"
        class="status-tile status-border status-{{ trip?.tripStatus || '' }}"
      >
        {{ trip?.tripStatus }}
      </div>
    </div>
    <ciao-form-field
      class="grow"
      label="Team Name"
      type="dropdown"
      [formControlInput]="tripDetailsFormGroup.get('userGroupId')"
      [selectOptions$]="userGroupOptions$"
    >
    </ciao-form-field>
  </div>

  <div class="date-time-fields">
    <ciao-form-field
      label="Start Date/Time"
      type="datetime"
      class="custom-date"
      [formControlInput]="tripDetailsFormGroup.get('startDate')"
      [defaultValue]="now"
    ></ciao-form-field>
    <ciao-form-field
      label="Return Date/Time"
      type="datetime"
      class="custom-date"
      [formControlInput]="tripDetailsFormGroup.get('endDate')"
    ></ciao-form-field>
  </div>
</div>
