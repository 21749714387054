<div>
  <div class="title-row">
    <div class="form-title">
      {{ formTitle }}
    </div>
    <div class="required-text">* Required Field</div>
  </div>
  <form>
    <div>
      <div class="side-by-side-inputs">
        <ciao-form-field
          type="text"
          label="First Name"
          class="half-width-input"
          [formControlInput]="formGroup.get('person.firstName')"
        ></ciao-form-field>
        <ciao-form-field
          type="text"
          label="Last Name"
          class="half-width-input"
          [formControlInput]="formGroup.get('person.lastName')"
        ></ciao-form-field>
      </div>
      <ciao-form-field
        label="User Type"
        type="dropdown"
        [selectOptions$]="jobOptions$"
        [formControlInput]="formGroup.get('person.jobTitle')"
      ></ciao-form-field>
      <div [ngClass]="{ displayNone: registrationContext }">
        <label class="email-section-title"
          >Email Addresses for Authentication <span>*</span></label
        >
        <br />
        <p class="email-section-subtext">
          A valid email is required: use a .GOV email for government device
          logins and your personal Login.gov email for personal device logins.
        </p>
        <ciao-form-field
          class="full-width"
          type="text"
          label="Primary Email"
          width="100%"
          [formControlInput]="formGroup.get('users.0.email')"
        ></ciao-form-field>

        <ciao-form-field
          type="checkbox"
          label="Email Notifications"
          [toolTip]="
            'To receive automated email notifications, like Quick Links for easy Check In, select this box.'
          "
          [formControlInput]="
            formGroup.get('users.0.notificationOptions.defaultSet')
          "
        >
        </ciao-form-field>
        <ciao-form-field
          class="full-width"
          type="text"
          label="Secondary Email"
          width="100%"
          [formControlInput]="formGroup.get('users.1.email')"
        ></ciao-form-field>

        <ciao-form-field
          type="checkbox"
          label="Email Notifications"
          [toolTip]="
            'To receive automated email notifications, like Quick Links for easy Check In, select this box.'
          "
          [formControlInput]="
            formGroup.get('users.1.notificationOptions.defaultSet')
          "
        >
        </ciao-form-field>
      </div>

      <!-- Deprecated: This will have to be removed when admin tab is established -->
      <ciao-form-field
        class="full-width"
        label="Assign Admin Roles"
        placeholder="No Roles Selected"
        [type]="'multiselect'"
        width="100%"
        *ngIf="!registrationContext"
        [formControlInput]="formGroup.get('regionRoles')"
        [selectOptions$]="AssignRegionRoleSelectOptions$"
        [compareWith]="AssignRegionRoleCompare"
        [customTrigger]="AssignRegionRoleCustomTriggerText"
      ></ciao-form-field>
    </div>
  </form>
</div>
