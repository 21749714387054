import { formatDate } from '@angular/common';
import { Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgbTimeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

/**
 * Model: `/\d\d:\d\d:00/`
 * @export
 * @class CiaoDateTimeStringAdapter
 * @extends {NgbTimeAdapter<string>}
 */
@Injectable()
export class CiaoDateTimeStringAdapter extends NgbTimeAdapter<string> {
  /**
   *
   * @param value Part of an iso string, `hh:mm:ss`, can be `hh:mm`.  This is in zulu time.
   * @returns {NgbTimeStruct} in local time
   */
  fromModel(value: string | null): NgbTimeStruct | null {
    if (!value) {
      return null;
    }
    let matcher = /(\d\d):(\d\d)/;
    let groups = value.match(matcher);
    if (!groups) {
      throw new Error(
        'Cannot find hh:mm in the provided string ' + JSON.stringify(value)
      );
    }
    return {
      hour: parseInt(groups[1], 10),
      minute: parseInt(groups[2], 10),
      second: 0,
    };
  }

  toModel(time: NgbTimeStruct | null): string | null {
    if (time == null) {
      return null;
    }
    let tempDate = new Date(`1970-01-01T00:00`);
    tempDate.setHours(time.hour);
    tempDate.setMinutes(time.minute);
    return formatDate(tempDate, 'HH:mm:ss', 'en-us');
  }
}

@Component({
  selector: 'ciao-form-field-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.less'],
  providers: [{ provide: NgbTimeAdapter, useClass: CiaoDateTimeStringAdapter }],
})
export class DateTimeInputComponent implements OnInit, OnDestroy {
  @Input() formControlInput: UntypedFormControl;
  @Input('label') label: string;
  @Input('inputId') inputId: string;

  dateControl = new UntypedFormControl('');
  timeControl = new UntypedFormControl('');
  secretFormControl: UntypedFormGroup = new UntypedFormGroup({
    date: this.dateControl,
    time: this.timeControl,
  });

  private subscriptions: Subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    // console.log(
    //   formatDate(new Date('2020-10-15T21:01'), 'yyyy-MM-dd', 'en-us')
    // );
    // console.log(formatDate(new Date('2020-10-15T21:01'), 'HH:mm:ss', 'en-us'));
    let obs1$ = this.formControlInput.valueChanges.pipe(
      distinctUntilChanged(),
      map((value) => new Date(value)),
      filter((date) => !isNaN(date.valueOf())),
      map((date) => {
        return {
          date: formatDate(date, 'yyyy-MM-dd', 'en-us'),
          time: formatDate(date, 'HH:mm:ss', 'en-us'),
        };
      }),
      tap((values) => this.secretFormControl.setValue(values))
    );
    let obs2$ = this.secretFormControl.valueChanges.pipe(
      filter((values) => Boolean(values.date && values.time)),
      map(({ date, time }) => `${date}T${time}`),
      distinctUntilChanged(),
      map((date) => new Date(date)),
      map((date) => date.toISOString()),
      tap((timestamp) => this.formControlInput.setValue(timestamp))
    );
    this.subscriptions.add(obs1$.subscribe());
    this.subscriptions.add(obs2$.subscribe());
    setTimeout(() => this.formControlInput.updateValueAndValidity());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
