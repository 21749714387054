import { Location } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TooltipPosition } from '@angular/material/tooltip';
import { BehaviorSubject, Subject, Subscription, of } from 'rxjs';
import {
  catchError,
  map,
  share,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

import { TripService } from '~app/services';
import {
  BasicTripDetails,
  TripDataChunk,
  TripModalFormComponent,
} from './types';
import {
  CrewMemberMetaAttributes,
  OkayAttributes,
  PlotAttributes,
  TripAttributes,
  VehicleEquipment,
} from '~app/models';
import { repeatLatestWhen } from '~app/utilities/repeat-latest-when.operator';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';
import { RolePermissionService } from '~app/services/role-permission.service';
import { TripStatus } from '~app/models/trip';

/** Readonly, Immutable list of tabs */
const Tabs = [
  'Details',
  'Locations',
  'Crew',
  'Equipment',
  'Attachments',
] as const;
export type TabName = (typeof Tabs)[number];

export const TableColumnsByTab: {
  [tab in TabName]: {
    head: string;
    accessor?: (item: TripDataChunk) => string;
  }[];
} = {
  Details: null,
  Locations: [
    {
      head: 'Description',
      accessor: (item: PlotAttributes) => item?.description,
    },
  ],
  Crew: [
    {
      head: 'Crew Member',
      accessor: (item: CrewMemberMetaAttributes) =>
        item?.crewMember?.displayName,
    },
    {
      head: 'Supervisor',
      accessor: (item: CrewMemberMetaAttributes) =>
        item?.supervisor?.displayName,
    },
  ],
  Equipment: [
    {
      head: 'Equipment',
      accessor: (item: VehicleEquipment) =>
        [
          item?.details,
          item?.state?.stateCode,
          item?.license,
          item?.make,
          item?.model,
        ]
          .filter((x) => x)
          .join(' | '),
    },
  ],
  Attachments: [
    {
      head: 'Attachments',
    },
  ],
};

@Component({
  selector: 'ciao-trip-modal-v2',
  templateUrl: './trip-modal-v2.component.html',
  styleUrls: ['./trip-modal-v2.component.less'],
})
export class TripModalV2Component implements OnInit {
  // consts
  readonly tabs = Tabs;

  singleLocation: boolean;
  singleCrewmember: boolean;

  private _currentTab: TabName = 'Details';
  set currentTab(value: TabName) {
    if (Tabs.includes(value)) {
      this._currentTab = value;
    } else {
      this._currentTab = 'Details';
    }
    this.refreshTab();
  }
  get currentTab() {
    return this._currentTab;
  }

  readonly refreshTab$ = new Subject<void>();
  readonly tripId$ = new BehaviorSubject<string>(null);
  readonly trip$ = this.tripId$.pipe(
    tap(() => (this.tripIsLoading = true)),
    switchMap((id) =>
      id
        ? this.tripService.findById(id)
        : of(this.tripService.getBlankTripForm())
    ),
    map((trip) => {
      if (this.duplicatingMode) {
        return this.tripService.duplicateTrip(trip);
      } else {
        return trip;
      }
    }),
    tap(() => (this.tripIsLoading = false)),
    repeatLatestWhen(this.refreshTab$),
    tap((trip) => this.refreshData(trip)),
    shareReplay(1)
  );

  get tripId() {
    return this.tripId$.value;
  }
  @Input() set tripId(value: string) {
    this.tripId$.next(value);
  }

  // ViewChild does not guarantee that the child implements the interface.  That is manual.
  @ViewChild('tripModal') tripModal: CiaoModalComponent;
  @ViewChild('currentForm') currentForm: TripModalFormComponent;

  modalIsOpen: boolean;
  tripIsLoading: boolean;
  tripIsValid: boolean;
  tripStatus: TripStatus;
  duplicatingMode: boolean;
  currentTableColumns: {
    head: string;
    accessor: (item: TripDataChunk) => string;
  }[] = null;
  currentData: TripDataChunk[] = null;

  currentlyEditingId: string = null;

  /** Temporary thing.  Will be replaced. */
  debugActionText: string;

  // display logic. getters and observables
  get isLoading() {
    return this.tripIsLoading; // And some other stuff?
  }
  get isNavigationDisabled() {
    return (
      !this.tripId ||
      this.duplicatingMode ||
      !this.modalIsOpen ||
      this.formIsDirty ||
      false
    );
  }

  get isNavLeftDisabled() {
    return this.currentTab === 'Details';
  }

  get isNavRightDisabled() {
    return this.currentTab === 'Attachments';
  }
  get formIsDirty() {
    return !!this.currentForm?.formGroup?.dirty;
  }
  get formIsValid() {
    return !!this.currentForm?.formGroup?.valid;
  }
  get canSave() {
    return this.formIsValid && this.tripStatus !== 'Closed';
  }
  get editRowMode() {
    return !!this.currentlyEditingId;
  }
  get canCheckout() {
    return (
      this.tripIsValid &&
      !this.formIsDirty &&
      !this.editRowMode &&
      this.tripStatus === 'Planned'
    );
  }

  get canViewTrip() {
    return (
      !this.currentlyEditingId &&
      !this.duplicatingMode &&
      this.tripIsValid &&
      !this.formIsDirty
    );
  }

  get canDeleteRow() {
    return (
      this.currentTab === 'Equipment' ||
      (this.currentTab === 'Locations' && !this.singleLocation) ||
      (this.currentTab === 'Crew' && !this.singleCrewmember)
    );
  }

  get tabNoun() {
    const nouns: { [tab in TabName]: string } = {
      Details: 'Details',
      Locations: 'Location',
      Crew: 'Crew Data',
      Equipment: 'Equipment',
      Attachments: 'Attachments',
    };
    return nouns[this.currentTab];
  }

  get tabTableNoun() {
    const nouns: { [tab in TabName]: string } = {
      Details: '',
      Locations: 'Locations',
      Crew: 'Crew Members',
      Equipment: 'Equipment',
      Attachments: '',
    };
    return nouns[this.currentTab];
  }

  get alternateCloseActionEnabled() {
    return this.duplicatingMode || !this.tripId;
  }

  //Tool tip on arrow hover
  get ArrowMessage() {
    return `${
      this.tripId
        ? 'Complete required fields and update.'
        : 'Complete required fields and save New Trip to access additional tabs.'
    }`;
  }

  canCreateTrips$ = this.rolePermissionService
    .searchMyAppliedPermissions({ permissionIds: ['create_trips'] })
    .pipe(
      map((perms) => perms.length === 0),
      share()
    );

  tabToolTipPosition: TooltipPosition = 'below';
  arrowToolTipPosition: TooltipPosition = 'above';
  showDelay = 0;

  subscriptions = new Subscription();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private tripService: TripService,
    private rolePermissionService: RolePermissionService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    let sub1 = this.trip$.subscribe();
    let sub2 = this.route.data
      .pipe(
        tap((data) => {
          this.duplicatingMode = data.duplicateTrip;
        })
      )
      .subscribe();
    this.subscriptions.add(sub1);
    this.subscriptions.add(sub2);
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  refreshTab() {
    this.currentTableColumns =
      TableColumnsByTab[this.currentTab] ?? TableColumnsByTab[0];
    this.currentForm?.formGroup.reset();
    this.currentlyEditingId = null;
    // Detect Changes first, which updates the View, before doing anything with the result.
    this.cdr.detectChanges();
    this.currentForm?.formGroup.reset();
    this.refreshTab$.next();
    this.cdr.detectChanges();
  }

  refreshData(trip: TripAttributes) {
    this.currentData = this.getListFromTripAndCurrentTab(trip) || null;

    if (this.currentTab === 'Details') {
      this.currentForm?.formGroup.patchValue(trip);
    }

    this.tripIsValid =
      trip.id &&
      trip.startDate &&
      trip.endDate &&
      trip.usergroup &&
      trip.locations.length > 0 &&
      trip.crewMembers.length > 0;

    this.singleLocation = trip?.locations.length === 1;
    this.singleCrewmember = trip?.crewMembers.length === 1;
    this.tripStatus = trip.tripStatus ?? 'Uncertain';
  }

  getListFromTripAndCurrentTab(trip: TripAttributes): TripDataChunk[] {
    return (
      {
        Locations: trip?.locations,
        Crew: trip?.crewMembers,
        Equipment: trip?.equipmentList,
      }[this.currentTab] || null
    );
  }
  getAttributeNameFromCurrentTab() {
    return (
      {
        Locations: 'locations',
        Crew: 'crewMembers',
        Equipment: 'equipmentList',
      }[this.currentTab] || null
    );
  }

  // handlers
  open(tab: TabName, itemToEdit: TripDataChunk) {
    this.currentTab = tab;
    //re-enable the tooltip
    this.modalIsOpen = true;
    if (itemToEdit) {
      this.onRowEdit(itemToEdit.id);
    }
    const modal = this.tripModal.openModal();
    modal.result.finally(() => this.onModalClose());
  }
  onModalInstance() {}
  onModalClose() {
    //disable tooltip so it doesnt show erroneously
    this.modalIsOpen = false;
    this.cdr.detectChanges();
    setTimeout(() => this.cdr.detectChanges(), 0);
  }
  onTripCreationCancel() {
    console.log('Cancel');
    this.tripModal.dismiss('trip creation cancled');
    this.location.back();
  }
  prevTab() {
    this.currentTab = {
      Details: 'Details',
      Locations: 'Details',
      Crew: 'Locations',
      Equipment: 'Crew',
      Attachments: 'Equipment',
    }[this.currentTab] as TabName;
  }
  nextTab() {
    this.currentTab = {
      Details: 'Locations',
      Locations: 'Crew',
      Crew: 'Equipment',
      Equipment: 'Attachments',
      Attachments: 'Attachments',
    }[this.currentTab] as TabName;
  }

  saveGuard() {
    if (this.currentForm.formGroup.invalid) {
      this.toastr.error('Cannot save.  The form is invalid.');
      throw new Error('Cannot save.  The form is invalid.');
    }
  }
  saveTrip(options: {
    transform: (trip: TripAttributes) => Partial<TripAttributes>;
    successMessage?: string;
    failureMessage?: string;
    allowInvalid?: boolean;
    clearForm?: boolean;
  }) {
    options.successMessage =
      options.successMessage || 'Successfully Saved Trip';
    options.failureMessage = options.failureMessage || 'Failed to Save Trip';
    if (!options.allowInvalid) {
      this.saveGuard();
    }
    let newTrip = false;
    this.tripIsLoading = true;
    return this.trip$.pipe(
      take(1),
      map((trip) => options.transform(trip)),
      tap((trip) => (newTrip = !trip.id)),
      switchMap((trip) => this.tripService.saveTrip(trip)),
      tap(() => this.toastr.success(options.successMessage)),
      catchError((err) => {
        this.toastr.error(options.failureMessage);
        console.error(err);
        return of<TripAttributes>(null);
      }),
      map((trip) => trip?.id || this.tripId),
      tap((id: string) => {
        if (options.clearForm) {
          this.onClearForm();
        }
        if (newTrip) {
          this.router.navigate(['/trip', 'id', id], {
            state: { openModal: true },
          });
        }
      })
    );
  }

  onDuplicateSend() {
    this.subscriptions.add(
      this.saveTrip({
        transform: (trip) => {
          const details = this.currentForm.finalResult as BasicTripDetails;
          trip.userGroupId = details.userGroupId ?? trip.userGroupId;
          trip.startDate = details.startDate;
          trip.endDate = details.endDate;
          this.currentForm.formGroup.reset(details);
          return trip;
        },
      }).subscribe()
    );
  }

  onSaveDetails() {
    this.subscriptions.add(
      this.saveTrip({
        transform: (trip) => {
          const details = this.currentForm.finalResult as BasicTripDetails;
          trip.userGroupId = details.userGroupId ?? trip.userGroupId;
          trip.startDate = details.startDate;
          trip.endDate = details.endDate;
          this.currentForm.formGroup.reset(details);
          return {
            id: trip.id,
            userGroupId: trip.userGroupId,
            startDate: trip.startDate,
            endDate: trip.endDate,
          };
        },
      }).subscribe()
    );
  }

  onAddRow() {
    this.subscriptions.add(
      this.saveTrip({
        transform: (trip) => {
          const listName = this.getAttributeNameFromCurrentTab();
          const row = this.currentForm.finalResult as TripDataChunk;
          const list = this.getListFromTripAndCurrentTab(trip);
          delete row.id;
          list.push(row);
          return { id: trip.id, [listName]: trip[listName] };
        },
        clearForm: true,
      }).subscribe()
    );
  }

  onUpdateRow() {
    this.subscriptions.add(
      this.saveTrip({
        transform: (trip) => {
          const listName = this.getAttributeNameFromCurrentTab();
          const updatedRow = this.currentForm.finalResult;
          const list = this.getListFromTripAndCurrentTab(trip);
          const index = list.findIndex((row) => row.id === updatedRow.id);
          list[index] = updatedRow;
          return { id: trip.id, [listName]: trip[listName] };
        },
        clearForm: true,
      }).subscribe()
    );
  }

  /** Edit Button on Row Menu From Table */
  onRowEdit(id: string) {
    let row = this.currentData.find((item) => item.id === id);
    this.currentlyEditingId = id;
    this.currentForm.formGroup.patchValue(row);
  }

  /** Delete Button on Row Menu From Table */
  onRowDelete(id: string) {
    this.subscriptions.add(
      this.saveTrip({
        transform: (trip) => {
          const listName = this.getAttributeNameFromCurrentTab();
          const list = this.getListFromTripAndCurrentTab(trip);
          const index = list.findIndex((item) => item.id === id);
          list.splice(index, 1);
          return { id: trip.id, [listName]: trip[listName] };
        },
        allowInvalid: true,
        clearForm: true,
      }).subscribe()
    );
  }

  onCancel() {
    this.onClearForm();
  }
  onClearForm() {
    this.currentlyEditingId = null;
    this.debugActionText = 'Cleared Form';
    this.currentForm.formGroup.reset();
    if (this.currentTab === 'Details') {
      this.refreshTab$.next();
    }
  }
  onCheckOut() {
    let checkOutOkay: OkayAttributes = {
      tripId: this.tripId,
      type: 'checkOut',
      timeOkay: new Date(),
      notes: 'Recorded from Edit Trip Modal',
    };
    this.tripService.addOkay(checkOutOkay).subscribe();
  }

  onViewTrip() {
    this.tripModal?.dismiss('View Trip Clicked');
  }
}
