<div class="checkbox-form-group">
  <label>{{ label ?? "Group Options" }}</label>
  <br />
  <a *ngIf="options?.length > 1 && selectAllEnabled" (click)="selectAll()">
    Select All {{ label ?? "Group Options" }}
  </a>
  <ciao-form-field
    *ngFor="let option of options"
    [label]="option.label"
    type="checkbox"
    [formControlInput]="control.controls[option.value]"
  />
  <!-- {{ control.errors | json }}
  {{ errors | json }}
  {{ valid | json }} -->
</div>
