<div class="container-fluid modal-form">
  <h3>{{ isUpdate ? "Update" : "Add" }} Equipment</h3>

  <div class="row">
    <ciao-form-field
      label="Vehicles/Equipment"
      type="textarea"
      placeholder="Describe details (e.g. fleet #, color, POV)"
      [toolTip]="
        'Please include a description of the vehicle and/or equipment that will be used for this trip. If vehicle, you can also fill out the fields below.'
      "
      [formControlInput]="tripEquipmentFormGroup.get('details')"
    ></ciao-form-field>
  </div>

  <div class="row">
    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="State"
      type="combobox"
      [formControlInput]="tripEquipmentFormGroup.get('state')"
      [selectOptions$]="stateOptions$"
      [compareWith]="compareStateForSelect"
    ></ciao-form-field>

    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="License #"
      type="input"
      [formControlInput]="tripEquipmentFormGroup.get('license')"
    ></ciao-form-field>

    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="Make"
      type="input"
      [formControlInput]="tripEquipmentFormGroup.get('make')"
    ></ciao-form-field>

    <ciao-form-field
      class="col-lg-3 col-sm-6 col-xs-12"
      label="Model"
      type="input"
      [formControlInput]="tripEquipmentFormGroup.get('model')"
    ></ciao-form-field>
  </div>
</div>
