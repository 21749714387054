<ciao-trip-modal-v2 #TripModal [tripId]="tripID"></ciao-trip-modal-v2>

<ng-container *ngIf="trip$ | async as trip">
  <div id="summary">
    <div class="header">
      <div id="title">
        <p>Trip Summary</p>
        <!-- TODO: Add back when pdf functionality is implemented -->
        <!-- <button class="btn-action-solid" (click)="saveToPdf()">
        Save to PDF
      </button> -->
      </div>
      <div class="btn-group">
        <button
          class="btn-action-light"
          (click)="deleteTripConfirmModal.openSmallModal()"
        >
          Delete Trip
        </button>
        <button
          class="btn-action-solid"
          [routerLink]="['/trip', 'duplicate', trip.id]"
        >
          Duplicate Trip
        </button>

        <button
          *ngIf="trip.tripStatus !== 'Closed'"
          class="btn-action-solid"
          (click)="TripModal.open(null, null)"
        >
          Edit Trip
        </button>
      </div>
    </div>

    <div id="summary-sections">
      <div id="left-side" class="column">
        <div id="checkout-btns" class="btns">
          <ciao-okay-modal
            [disabled]="!canOkay(trip, 'checkOut')"
            okayType="checkOut"
            class="btn"
            buttonClass="btn-action-solid"
            [trip]="trip"
          ></ciao-okay-modal>
          <ciao-okay-modal
            [disabled]="!canOkay(trip, 'okay')"
            okayType="okay"
            class="btn"
            buttonClass="btn-action-solid"
            [trip]="trip"
          ></ciao-okay-modal>
          <ciao-okay-modal
            [disabled]="!canOkay(trip, 'checkIn')"
            okayType="checkIn"
            class="btn"
            buttonClass="btn-action-solid"
            [trip]="trip"
          ></ciao-okay-modal>
        </div>
        <div id="logs" class="section list scroll">
          <h2 class="title">Trip Log</h2>
          <p class="margin-left" *ngIf="trip.okays?.length < 1">
            Trip actions will appear here.
          </p>
          <div class="item" *ngFor="let okay of trip?.okays">
            <div class="status" tabindex="0">
              <p>
                <span> Type</span><br />
                {{ getReadableOkayType(okay) }}
              </p>
              <p>
                <span>Recorded By</span><br />{{
                  okay?.recordedBy?.person?.displayName
                }}
              </p>
              <p>
                <span>Actual Time/Date</span><br />
                {{ formatDate(okay?.createdAt) }}
              </p>
              <p>
                <span>Entered Time/Date</span><br />
                {{ formatDate(okay?.timeOkay) }}
              </p>
              <p *ngIf="okay?.type === 'checkIn'">
                <span>Crew Member(s)</span><br />
                <ng-container *ngFor="let crew of okay.crewMembersSelected">
                  {{ crew.displayName }}<br />
                </ng-container>
              </p>
              <p *ngIf="okay?.notes">
                <span>Notes</span><br />
                {{ okay?.notes }}
              </p>
            </div>
            <hr />
          </div>
        </div>
      </div>

      <div id="right-side" class="column">
        <div id="details" class="section">
          <div class="table-header">
            <h3 aria-label="details table header" class="title">Details</h3>
          </div>
          <div class="info-row">
            <div>
              <span class="data-title"> Team: </span>

              <span
                >{{ trip?.usergroup?.labelPrefix }}
                {{ trip?.usergroup?.label }}</span
              >
            </div>
            <div>
              <span class="data-title"> Start Time/Date: </span>
              <span>{{ formatDate(trip?.startDate) }}</span>
            </div>
            <div>
              <span class="data-title"> Return Time/Date: </span
              ><span>{{ formatDate(trip?.endDate) }}</span>
            </div>
          </div>
        </div>

        <div id="locations" class="section">
          <ng-container>
            <div class="table-header">
              <h3 id="locations-table-header" class="title">Locations</h3>
              <button
                *ngIf="trip.tripStatus !== 'Closed'"
                aria-label="edit trip locations"
                (click)="TripModal.open('Locations', null)"
              >
                <span class="screenreader">Edit</span>
                <fa-icon class="icon" [icon]="faPlus"></fa-icon>
              </button>
            </div>
            <div class="scroll">
              <table
                aria-describedby="locations-table-header"
                *ngIf="trip?.locations?.length > 0; else locMissingContent"
              >
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>State</th>
                    <th>County</th>
                    <th>County Code</th>
                    <th>Plot Number</th>
                    <th>LAT/LONG of GPS Coordinates</th>
                    <th
                      *ngIf="trip.tripStatus !== 'Closed'"
                      class="menu-column fixed"
                    >
                      <span class="screenreader">Options</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let location of trip?.locations" tabindex="0">
                    <td class="long-content">
                      {{ location?.description }}
                    </td>
                    <td class="nowrap">
                      {{ location?.county?.state?.stateCode }}
                    </td>
                    <td class="nowrap">
                      {{ location?.county?.countyName }}
                    </td>
                    <td class="nowrap">{{ location?.countyCode }}</td>
                    <td class="nowrap">{{ location?.plotNumber }}</td>
                    <td class="nowrap">{{ location?.textCoords }}</td>
                    <td *ngIf="trip.tripStatus !== 'Closed'" class="fixed">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="rowOptionsMenu"
                        [matMenuTriggerData]="{ id: location.id }"
                        aria-label="Row Options"
                        class="semantic-button"
                      >
                        <span class="screenreader">Row Options</span>
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </td>

                    <mat-menu #rowOptionsMenu>
                      <ng-template matMenuContent let-id="id">
                        <button
                          mat-menu-item
                          (click)="TripModal.open('Locations', location)"
                        >
                          <mat-icon>edit</mat-icon>
                          Edit
                        </button>
                        <button
                          mat-menu-item
                          [matMenuTriggerFor]="deleteRowConfirmation"
                          [matMenuTriggerData]="{ id: id }"
                          *ngIf="trip?.locations.length > 1"
                        >
                          <mat-icon>delete</mat-icon> Delete
                        </button>
                      </ng-template>
                    </mat-menu>
                  </tr>
                </tbody>

                <mat-menu #deleteRowConfirmation>
                  <ng-template matMenuContent let-id="id">
                    <button
                      mat-menu-item
                      (click)="onRowDelete(id, 'Locations')"
                    >
                      <mat-icon style="color: red">delete</mat-icon>
                      Confirm Delete
                    </button>
                  </ng-template>
                </mat-menu>
              </table>
            </div>
          </ng-container>

          <ng-template #locMissingContent>
            <h3 class="default-content">No Locations assigned to trip</h3>
          </ng-template>
        </div>
        <div id="crew" class="section">
          <ng-container>
            <div class="table-header">
              <h3 id="crew-members-table-header" class="title">Crew Members</h3>
              <button
                *ngIf="trip.tripStatus !== 'Closed'"
                aria-label="edit crew members"
                (click)="TripModal.open('Crew', null)"
              >
                <span class="screenreader">Edit</span>
                <fa-icon class="icon" [icon]="faPlus"></fa-icon>
              </button>
            </div>
            <div class="scroll">
              <table
                aria-describedby="crew-members-table-header"
                *ngIf="trip?.crewMembers?.length > 0; else crewMissingContent"
              >
                <thead>
                  <tr>
                    <th>Team Member</th>
                    <th>Cell Phone</th>
                    <th>Supervisor</th>
                    <th>Supervisor Cell Phone</th>
                    <th>Sat Phone</th>
                    <th>Sat Comm</th>
                    <th>Other Notes</th>
                    <th
                      *ngIf="trip.tripStatus !== 'Closed'"
                      class="menu-column fixed"
                    >
                      <span class="screenreader">Options</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let member of trip?.crewMembers"
                    tabindex="0"
                    [ngClass]="{
                      disabled:
                        !showCheckedIn(trip) &&
                        member?.crewMemberStatus === 'CheckedIn'
                    }"
                  >
                    <td class="fit-width">
                      <span class="with-avatar">
                        <div
                          class="avatar"
                          *ngIf="member?.crewMember?.displayName"
                        >
                          <!-- TODO - Add Avatar Img here -->
                        </div>
                        <div class="text">
                          {{ member?.crewMember?.displayName }}
                          {{
                            member?.crewMemberStatus === "CheckedIn" &&
                            trip.tripStatus !== "Closed"
                              ? "(Checked In)"
                              : ""
                          }}
                        </div>
                      </span>
                    </td>

                    <td class="nowrap">{{ member?.fsCellPhone }}</td>
                    <td class="nowrap">
                      {{ member?.supervisor?.displayName }}
                    </td>
                    <td class="nowrap">{{ member?.supervisorPhone }}</td>
                    <td class="nowrap">{{ member?.satPhone }}</td>
                    <td class="nowrap">{{ member?.satLocatorId }}</td>
                    <td class="long-content">{{ member?.notes }}</td>
                    <td
                      *ngIf="
                        trip.tripStatus !== 'Closed' &&
                        member?.crewMemberStatus !== 'CheckedIn'
                      "
                      class="fixed"
                    >
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="rowOptionsMenu"
                        [matMenuTriggerData]="{ id: member.id }"
                        aria-label="Row Options"
                        class="semantic-button"
                      >
                        <span class="screenreader">Row Options</span>
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </td>
                    <mat-menu #rowOptionsMenu>
                      <ng-template matMenuContent let-id="id">
                        <button
                          mat-menu-item
                          (click)="TripModal.open('Crew', member)"
                        >
                          <mat-icon>edit</mat-icon>
                          Edit
                        </button>
                        <button
                          mat-menu-item
                          [matMenuTriggerFor]="deleteRowConfirmation"
                          [matMenuTriggerData]="{ id: id }"
                          *ngIf="trip?.crewMembers.length > 1"
                        >
                          <mat-icon>delete</mat-icon> Delete
                        </button>
                      </ng-template>
                    </mat-menu>
                  </tr>
                </tbody>
                <mat-menu #deleteRowConfirmation>
                  <ng-template matMenuContent let-id="id">
                    <button mat-menu-item (click)="onRowDelete(id, 'Crew')">
                      <mat-icon style="color: red">delete</mat-icon>
                      Confirm Delete
                    </button>
                  </ng-template>
                </mat-menu>
              </table>
            </div>
          </ng-container>

          <ng-template #crewMissingContent>
            <h3 class="default-content">No Crew Members assigned to trip</h3>
          </ng-template>
        </div>
        <div id="equipment" class="section">
          <ng-container>
            <div class="table-header">
              <h3 id="equipment-table-header" class="title">Equipment</h3>
              <button
                *ngIf="trip.tripStatus !== 'Closed'"
                aria-label="edit equipment"
                (click)="TripModal.open('Equipment', null)"
              >
                <span class="screenreader">Edit</span>
                <fa-icon class="icon" [icon]="faPlus"></fa-icon>
              </button>
            </div>
            <div class="scroll">
              <table
                aria-describedby="equipment-table-header"
                *ngIf="
                  trip?.equipmentList?.length > 0;
                  else equipmentMissingContent
                "
              >
                <thead>
                  <tr>
                    <th>Vehicles/Equipment</th>
                    <th>State</th>
                    <th>License #</th>
                    <th>Make</th>
                    <th>Model</th>
                    <th
                      *ngIf="trip.tripStatus !== 'Closed'"
                      class="menu-column fixed"
                    >
                      <span class="screenreader">Options</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let vehicle of trip?.equipmentList" tabindex="0">
                    <td class="long-content">{{ vehicle?.details }}</td>
                    <td class="nowrap">{{ vehicle?.state?.stateName }}</td>
                    <td class="nowrap">{{ vehicle?.license }}</td>
                    <td class="nowrap">{{ vehicle?.make }}</td>
                    <td class="nowrap">{{ vehicle?.model }}</td>
                    <td *ngIf="trip.tripStatus !== 'Closed'" class="fixed">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="rowOptionsMenu"
                        [matMenuTriggerData]="{ id: vehicle.id }"
                        aria-label="Row Options"
                        class="semantic-button"
                      >
                        <span class="screenreader">Row Options</span>
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </td>
                    <mat-menu #rowOptionsMenu>
                      <ng-template matMenuContent let-id="id">
                        <button
                          mat-menu-item
                          (click)="TripModal.open('Equipment', vehicle)"
                        >
                          <mat-icon>edit</mat-icon>
                          Edit
                        </button>
                        <button
                          mat-menu-item
                          [matMenuTriggerFor]="deleteRowConfirmation"
                          [matMenuTriggerData]="{ id: id }"
                        >
                          <mat-icon>delete</mat-icon> Delete
                        </button>
                      </ng-template>
                    </mat-menu>
                  </tr>
                </tbody>
                <mat-menu #deleteRowConfirmation>
                  <ng-template matMenuContent let-id="id">
                    <button
                      mat-menu-item
                      (click)="onRowDelete(id, 'Equipment')"
                    >
                      <mat-icon style="color: red">delete</mat-icon>
                      Confirm Delete
                    </button>
                  </ng-template>
                </mat-menu>
              </table>
            </div>
          </ng-container>

          <ng-template #equipmentMissingContent>
            <h3 class="default-content">No Equipment assigned to trip</h3>
          </ng-template>
        </div>
        <div id="attachments" class="section">
          <ng-container>
            <div class="table-header">
              <h3 id="attachment-table-header" class="title">Attachments</h3>
              <button
                *ngIf="trip.tripStatus !== 'Closed'"
                aria-label="edit attachments"
                (click)="TripModal.open('Attachments', null)"
              >
                <span class="screenreader">Edit</span>
                <fa-icon class="icon" [icon]="faPlus"></fa-icon>
              </button>
            </div>
            <div class="scroll">
              <table
                aria-describedby="attachment-table-header"
                *ngIf="
                  trip?.attachments?.length > 0;
                  else attachmentMissingContent
                "
              >
                <thead>
                  <tr>
                    <th>Filename</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Date Uploaded</th>
                    <th
                      *ngIf="trip.tripStatus !== 'Closed'"
                      class="menu-column fixed"
                    >
                      <span class="screenreader">Options</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let attachment of trip?.attachments" tabindex="0">
                    <td class="long-content">
                      <a
                        target="_blank"
                        rel="noopener"
                        [href]="attachment.viewHref"
                      >
                        <mat-icon class="size-1em">attach_file</mat-icon>
                        {{ attachment.fileName }}
                      </a>

                      <mat-progress-bar
                        *ngIf="attachment.status === 'deleting'"
                        mode="buffer"
                      ></mat-progress-bar>
                    </td>
                    <td class="nowrap">{{ attachment.extension }}</td>
                    <td class="nowrap">{{ attachment.fileSize | filesize }}</td>
                    <td class="nowrap">
                      {{
                        attachment.createdAt | ciaoDate : "datetime" : "simple"
                      }}
                    </td>
                    <td *ngIf="trip.tripStatus !== 'Closed'" class="fixed">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="rowOptionsMenu"
                        [matMenuTriggerData]="{ id: attachment.id }"
                        aria-label="Row Options"
                        class="semantic-button"
                      >
                        <span class="screenreader">Row Options</span>
                        <mat-icon>more_vert</mat-icon>
                      </button>
                    </td>
                    <mat-menu #rowOptionsMenu>
                      <ng-template matMenuContent let-id="id">
                        <button
                          mat-menu-item
                          (click)="TripModal.open('Attachments', null)"
                        >
                          <mat-icon>edit</mat-icon>
                          Edit
                        </button>
                        <button
                          mat-menu-item
                          [matMenuTriggerFor]="deleteRowConfirmation"
                          [matMenuTriggerData]="{ attachment: attachment }"
                        >
                          <mat-icon>delete</mat-icon> Delete
                        </button>
                      </ng-template>
                    </mat-menu>
                  </tr>
                </tbody>
                <mat-menu #deleteRowConfirmation>
                  <ng-template matMenuContent let-attachment="attachment">
                    <button
                      mat-menu-item
                      (click)="
                        tripService.removeAttachment(attachment).subscribe()
                      "
                    >
                      <mat-icon style="color: red">delete</mat-icon>
                      Confirm Delete
                    </button>
                  </ng-template>
                </mat-menu>
              </table>
            </div>
          </ng-container>

          <ng-template #attachmentMissingContent>
            <h3 class="default-content">No Attachments</h3>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Delete Trip Modal -->
<ciao-modal #deleteTripConfirmModal title="Confirm Delete Trip?">
  <span modalBody>
    <div class="container">
      <div class="row">
        <h5>Are you sure you want to delete this trip?</h5>
      </div>
    </div>
  </span>
  <span modalFooter>
    <div class="justify-content-center d-flex">
      <button
        class="btn-action-light center"
        (click)="cancelDeleteTripClicked($event)"
      >
        Cancel
      </button>

      <button
        class="btn-action-solid center"
        (click)="confirmDeleteTripClicked($event)"
      >
        Delete Trip
      </button>
    </div>
  </span>
</ciao-modal>
