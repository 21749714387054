import { Component, OnInit, ViewChild } from '@angular/core';
import { TripModalComponent } from '../trip/trip-modal/trip-modal.component';
import { Router } from '@angular/router';
import { headingResources, headingGreetings } from './greeting-data-map';
import { faCompass } from '@fortawesome/free-solid-svg-icons/faCompass';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.less'],
})
export class LandingComponent implements OnInit {
  @ViewChild('newTripModal') newTripModal: TripModalComponent;

  majorHead;
  headings = headingResources;
  messages = headingGreetings;

  compass = faCompass;

  constructor(private router: Router) {}

  ngOnInit(): void {
    let resourcesIndex = Math.floor(Math.random() * this.headings.length);
    let greetingsIndex = Math.floor(Math.random() * this.messages.length);

    let body = document.getElementById('mainContainer');

    body.style.backgroundImage = `url(${this.headings[resourcesIndex].asset})`;
    body.style.color = `${this.headings[resourcesIndex].headingColor}`;

    this.majorHead = this.messages[greetingsIndex].message;
  }

  openNewTripModal = () => {
    this.router.navigate(['trip/new']);
  };
}
