import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Observable, Subscription, of } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  share,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import {
  CountyAttributes,
  PlotAttributes,
  ResourceAttributes,
  StateAttributes,
  TripAttributes,
} from '~app/models';
import { LocationService } from '~app/services';

@Component({
  selector: 'ciao-trip-locations-form',
  templateUrl: './trip-locations-form.component.html',
  styleUrls: [
    './trip-locations-form.component.less',
    '../trip-modal.component.less',
  ],
})
export class TripLocationsFormComponent implements OnInit {
  @Input() trip: TripAttributes;
  @Input() locationId: string;
  @Input() mode: 'create' | 'update' | 'add' = 'add';
  @Input() openTab: string;
  @Output() formStatusChange = new EventEmitter<boolean>();
  isUpdate: boolean;
  location: PlotAttributes;

  countyCodesAutocomplete$: Observable<string[]>;
  stateOptions$: Observable<{ value: StateAttributes; label: string }[]>;
  countyOptions$: Observable<{ value: CountyAttributes; label: string }[]>;

  tripLocationFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    description: new UntypedFormControl('', Validators.required),
    state: new UntypedFormControl(''),
    county: new UntypedFormControl(''),
    countyCode: new UntypedFormControl(null),
    plotNumber: new UntypedFormControl(null),
    coords: new UntypedFormControl(null),
    textCoords: new UntypedFormControl(null),
  });

  subscriptions: Subscription = new Subscription();
  constructor(private locationService: LocationService) {}

  ngOnInit(): void {
    this.setupLocationData();
    this.isUpdate = this.mode === 'update';

    if (this.isUpdate && this.openTab === 'locations') {
      this.populateLocationData();
    }
    this.setupFormStatusChangeEmission();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  compareWithId(a: ResourceAttributes, b: ResourceAttributes) {
    return a?.id === b?.id;
  }

  findLocationData() {
    this.location = this.trip?.locations?.find(
      (plot) => plot.id === this.locationId
    );
  }

  public getFormData() {
    return this.tripLocationFormGroup;
  }

  populateLocationData() {
    if (!this.locationId) return;
    this.findLocationData();

    this.countyOptions$
      .pipe(
        take(1), // take(1) so dont have to add to subscription destruction variable
        delay(0),
        tap(() => {
          this.tripLocationFormGroup.patchValue({
            id: this.locationId,
            description: this.location?.description || '',
            state: this.location?.address?.state || '',
            county: this.location?.county?.id || '',
            countyCode: this.location?.countyCode || null,
            plotNumber: this.location?.plotNumber || null,
            coords: this.location?.coords || null,
            textCoords: this.location?.textCoords || null,
          });
        })
      )
      .subscribe();
  }

  setupFormStatusChangeEmission() {
    let formStatusSub = this.tripLocationFormGroup.statusChanges.subscribe(
      (status) => {
        this.formStatusChange.emit(status === 'VALID');
      }
    );

    this.subscriptions.add(formStatusSub);
  }

  setupLocationData() {
    this.countyCodesAutocomplete$ = this.locationService.allCounties$.pipe(
      map((counties) => counties.map((county) => county.id))
    );
    let countyCodeControl = this.tripLocationFormGroup.get('countyCode');
    let stateControl = this.tripLocationFormGroup.get('state');
    let countyControl = this.tripLocationFormGroup.get('county');

    this.subscriptions.add(
      countyCodeControl.valueChanges
        .pipe(
          filter((input) => input && countyCodeControl.valid),
          switchMap((input) => {
            return this.locationService.findCountyById(input);
          }),
          filter((county) => Boolean(county)),
          distinctUntilChanged(),
          tap((county) => stateControl.setValue(county.state)),
          tap((county) => countyControl.setValue(county))
        )
        .subscribe()
    );
    this.subscriptions.add(
      countyControl.valueChanges
        .pipe(
          filter((input) => input && countyControl.valid),
          filter(
            (county: CountyAttributes) => county.id !== countyCodeControl.value
          ),
          distinctUntilChanged(),
          tap((county) => countyCodeControl.setValue(county.id)),
          tap((county) => stateControl.setValue(county.state))
        )
        .subscribe()
    );
    this.stateOptions$ = this.locationService.allStates$.pipe(
      map((states) =>
        states.map((state) => ({ value: state, label: state.stateCode }))
      )
    );
    this.countyOptions$ = stateControl.valueChanges.pipe(
      map((state: StateAttributes) => state?.counties),
      startWith([]),
      switchMap((counties) =>
        counties?.length ? of(counties) : this.locationService.allCounties$
      ),
      map((counties) =>
        counties.map((county) => ({
          value: county,
          label: `${county.countyName}, ${county.state.stateCode}`,
        }))
      ),
      share()
    );
  }
}

const lengthValidatorMin1: ValidatorFn = function (control: UntypedFormControl) {
  if (control.value.length > 0) {
    return null;
  }
  if (control.value.length < 0) {
    return {
      unknownError: `Length is less than zero.  I don't know how this happened.`,
    };
  } else if (control.value.length === 0) {
    return {
      MinimumLength: `You must have at least one Trip Location to make this a valid trip.`,
    };
  }
};
