import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';

import { combineLatest, Subscription, BehaviorSubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';

import { PersonAttributes, TripAttributes } from '~app/models';
import { CrewMemberMetaAttributes } from '~app/models/trip';
import { AuthService } from '~app/services';
import { UserGroupService } from '~app/services/user-group.service';

@Component({
  selector: 'ciao-trip-crew-form',
  templateUrl: './trip-crew-form.component.html',
  styleUrls: [
    './trip-crew-form.component.less',
    '../trip-modal.component.less',
  ],
})
export class TripCrewFormComponent implements OnInit, OnChanges {
  @Input() userGroupId: string;
  @Input() crewId: string;
  @Input() trip: TripAttributes;
  @Input() mode: 'create' | 'update' | 'add' = 'add';
  @Input() openTab: string;
  @Output() formStatusChange = new EventEmitter<boolean>();
  isUpdate: boolean;
  currentlyPatching: boolean;
  /**updated in ngOnChanges */
  readonly userGroupId$ = new BehaviorSubject<string>(null);

  crewMemberForm = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    crewMember: new UntypedFormControl('', [
      Validators.required,
      this.crewValidator.bind(this),
    ]),
    supervisor: new UntypedFormControl('', [
      Validators.required,
      this.supervisorValidator.bind(this),
    ]),
    fsCellPhone: new UntypedFormControl('', [Validators.required]),
    supervisorPhone: new UntypedFormControl('', [Validators.required]),
    satPhone: new UntypedFormControl(''),
    satLocatorId: new UntypedFormControl(''),
    notes: new UntypedFormControl(''),
  });

  crewMemberToEdit: CrewMemberMetaAttributes;
  readonly crewMemberAndSupervisorList$ = this.userGroupId$.pipe(
    filter((x) => !!x),
    switchMap((userGroupId) =>
      this.userGroupService.getCrewMemberDropdownLists(userGroupId)
    )
  );
  readonly crewMemberOptions$ = this.crewMemberAndSupervisorList$.pipe(
    map((listByRoles) => listByRoles.crew_member),
    shareReplay(1)
  );

  readonly supervisorOptions$ = this.crewMemberAndSupervisorList$.pipe(
    map((listByRoles) => listByRoles.supervisor),
    shareReplay(1)
  );
  private subscriptions: Subscription = new Subscription();

  helpTextActive = false;

  //font awesome
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;

  constructor(
    private userGroupService: UserGroupService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.refreshSubscriptions();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userGroupId) {
      this.userGroupId$.next(changes.userGroupId.currentValue);
    }
    if (changes.mode) {
      if (this.mode === 'update') {
        this.populateCrewMemberData();
      }

      this.refreshSubscriptions();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  refreshSubscriptions() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.autoFillPhone();
    this.autoFillCrewMember();
    this.setupFormStatusEmission();
  }

  autoFillPhone() {
    const crewControl = this.crewMemberForm.get('crewMember');
    const crewCellControl = this.crewMemberForm.get('fsCellPhone');
    const supervisorControl = this.crewMemberForm.get('supervisor');
    const supervisorCellControl = this.crewMemberForm.get('supervisorPhone');

    let crewSub = crewControl.valueChanges
      .pipe(
        startWith(crewControl.value),
        distinctUntilChanged(this.comparePersonForSelect),
        skip(1),
        filter(() => !this.currentlyPatching),
        filter((input) => input),
        tap((crewMember) => {
          crewCellControl.setValue(crewMember?.cellPhone);
        }),
        tap(() => supervisorControl.updateValueAndValidity())
      )
      .subscribe();

    let supervisorSub = supervisorControl.valueChanges
      .pipe(
        startWith(supervisorControl.value),
        distinctUntilChanged(this.comparePersonForSelect),
        skip(1),
        filter(() => !this.currentlyPatching),
        filter((input) => input),
        tap((supervisor) => {
          supervisorCellControl.setValue(supervisor?.cellPhone);
        }),
        tap(() => crewControl.updateValueAndValidity())
      )
      .subscribe();

    this.subscriptions.add(crewSub);
    this.subscriptions.add(supervisorSub);
  }

  autoFillCrewMember() {
    let currentPerson$ = this.authService.currentUser$.pipe(
      map((currentUser) => currentUser?.person)
    );
    let sub = combineLatest([this.crewMemberOptions$, currentPerson$])
      .pipe(
        filter((_) => this.mode === 'create'),
        tap(([crewMemberOptions, currentPerson]) => {
          let isCrewPatched = crewMemberOptions.find(
            (option) => option.value.id === currentPerson.id
          )?.value;
          // if team selected contains current user then patch vals
          if (isCrewPatched) {
            this.crewMemberForm.controls.crewMember.setValue(currentPerson);
          }
        })
      )
      .subscribe();
    this.subscriptions.add(sub);
  }

  clearCrewAndSupervisorFormFields() {
    // this line is somewhat useless since if you are a member of this team you will
    // automatically be added to the crew member field
    const controls = this.crewMemberForm.controls;

    controls.crewMember.reset();
    controls.fsCellPhone.reset();
    controls.supervisor.reset();
    controls.supervisorPhone.reset();
  }

  comparePersonForSelect(a: any, b: any) {
    if (!a || !b) return false;

    const bothId = a?.id && b?.id;
    if (bothId) return a?.id === b?.id;

    if (a?.id) return a?.id === b;
    if (b?.id) return a === b?.id;

    return a === b;
  }

  findCrewMemberById(): CrewMemberMetaAttributes {
    return this.trip.crewMembers.find((crewMember) => {
      return crewMember.id === this.crewId;
    });
  }

  public getFormData() {
    return this.crewMemberForm;
  }

  populateCrewMemberData() {
    if (!this.crewId) return;

    this.crewMemberToEdit = this.findCrewMemberById();
    this.crewMemberOptions$
      .pipe(
        take(1), // take(1) so dont have to add to subscription destruction variable
        tap(() => {
          this.crewMemberForm.setValue({
            id: this.crewId,
            crewMember: this.crewMemberToEdit?.crewMember,
            supervisor: this.crewMemberToEdit?.supervisor,
            supervisorPhone: this.crewMemberToEdit?.supervisorPhone,
            fsCellPhone: this.crewMemberToEdit?.fsCellPhone,
            satPhone: this.crewMemberToEdit?.satPhone,
            satLocatorId: this.crewMemberToEdit?.satLocatorId,
            notes: this.crewMemberToEdit?.notes,
          });
          this.crewMemberForm.markAllAsTouched();
        })
      )
      .subscribe();
  }

  setupFormStatusEmission() {
    let formStatusSub = this.crewMemberForm.statusChanges.subscribe(
      (status) => {
        this.formStatusChange.emit(status === 'VALID');
      }
    );

    this.subscriptions.add(formStatusSub);
  }

  crewSupeValidatorSharedInput() {
    const crewMemberId = this.crewMemberForm?.controls.crewMember.value?.id;
    const supervisorId = this.crewMemberForm?.controls.supervisor.value?.id;
    // Don't count the meta item that this one comes from.
    const pastMetas = this.trip?.crewMembers.filter(
      (meta) => meta.id !== this.crewId
    );
    const pastCrewMemberIds = pastMetas?.map((meta) => meta.crewMember?.id);
    const pastSupervisorIds = pastMetas?.map((meta) => meta.supervisor?.id);
    return {
      crewMemberId,
      supervisorId,
      pastCrewMemberIds,
      pastSupervisorIds,
      crewEqualsSuper: crewMemberId === supervisorId,
    };
  }

  crewValidator(control: UntypedFormControl) {
    const info = this.crewSupeValidatorSharedInput();
    if (!info.crewMemberId) {
      return null;
    }
    const crewIsSuperInMeta = info.pastSupervisorIds?.includes(
      info.crewMemberId
    );

    if (info.crewEqualsSuper || crewIsSuperInMeta) {
      return { crewIsSupervisor: `Crew cannot be an existing supervisor` };
    } else {
      return null;
    }
  }

  supervisorValidator(control: UntypedFormControl) {
    const info = this.crewSupeValidatorSharedInput();
    if (!info.supervisorId) {
      return null;
    }
    const superIsCrewInMeta = info.pastCrewMemberIds?.includes(
      info.supervisorId
    );

    if (info.crewEqualsSuper || superIsCrewInMeta) {
      return {
        crewIsSupervisor: `Supervisor cannot be an existing crew member`,
      };
    } else {
      return null;
    }
  }
}
