import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';
import { PlotAttributes, TripAttributes } from '~app/models';
import { PersonService, TextService, TripService } from '~app/services';

import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons/faArrowCircleRight';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons/faAngleDown';
import { TripModalComponent } from '../trip-modal/trip-modal.component';
import { VehicleEquipment } from '~app/models/equipment';
import { ToastrService } from 'ngx-toastr';
import { LatLong } from '~app/components/shared/forms/field/lat-long/lat-long.functions';

@Component({
  selector: 'ciao-trip-record-by-id',
  templateUrl: './trip-record-by-id.component.html',
  styleUrls: ['./trip-record-by-id.component.less'],
})
export class TripRecordByIdComponent implements AfterViewInit {
  @ViewChild('deleteTripConfirmModal')
  deleteTripConfirmModal: CiaoModalComponent;

  @ViewChild('tripModal') tripModal: TripModalComponent;

  private tripID = '';
  trip$: Observable<TripAttributes> = this.setTrip();
  private todayDate = new Date();

  //font awesome
  faPen = faPen;
  faCircle = faArrowCircleRight;
  faCaret = faAngleDown;

  sendingTrip = false;
  errorMessage: string = '';
  lastAction: '' | 'Created' | 'Deleted' | 'Updated' = '';

  //#region Trip Log Table Data

  onSendOkay = () => {
    this.refreshTrip();
  };

  formatDate = (date: Date | string, isSimple = true) => {
    return isSimple
      ? this.textService.formatSimpleDatetime(date)
      : this.textService.formatDatetime(date);
  };

  getReadableOkayType(okayType) {
    switch (okayType) {
      case 'checkOut':
        return 'Check Out';
      case 'checkIn':
        return 'Check In';
      case 'okay':
        return 'Okay';
      default:
        return '';
    }
  }

  //#endregion

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private personService: PersonService,
    private toastrService: ToastrService,
    private tripService: TripService,
    private textService: TextService
  ) {}

  ngAfterViewInit(): void {
    if (this.route.snapshot.routeConfig.path === 'new') {
      this.openModal(null, 'details', 'create');
    } else {
      this.refreshTrip();
    }
  }

  setTrip() {
    return of(this.tripService.getBlankTripForm());
  }

  confirmDeleteTripClicked(ev) {
    try {
      // this trip pipe to accomplish the same thing
      this.tripService
        .deleteTrip(this.tripID)
        .pipe(
          take(1),
          tap((trip) => {
            this.router.navigate(['/search']);
            this.deleteTripConfirmModal.close('');
          })
        )
        .subscribe();
      this.toastrService.success(
        'Trip has been successfully deleted.',
        'Success'
      );
    } catch (err) {
      this.toastrService.error(
        `There was an error deleting the trip.`,
        'Error'
      );
    }
  }

  cancelDeleteTripClicked(ev) {
    ev.preventDefault();
    this.deleteTripConfirmModal.close('');
  }

  deleteTripClicked(ev) {
    ev.preventDefault();
    this.deleteTripConfirmModal.openSmallModal();
  }

  formatAddress(location: PlotAttributes) {
    const addressStr = this.tripService.formatFullAddressAsString(location);
    return this.textService.shortenText(addressStr, 20);
  }

  openModal(
    propId: string | null,
    tabName: keyof typeof this.tripModal.tabs,
    modalModeOverride?: 'create' | 'update' | 'add'
  ) {
    this.tripModal.openModal(propId, tabName, modalModeOverride);
  }

  refreshTrip() {
    this.trip$ = this.route.params.pipe(
      map((value) => value.id),
      switchMap((id) => {
        if (id) {
          return this.tripService.findById(id);
        } else {
          return of(this.tripService.getBlankTripForm());
        }
      }),
      tap((trip) => {
        this.tripID = trip.id;

        return trip;
      })
    );
  }

  displayCoords(coords: LatLong) {
    if (!coords) {
      return '';
    }
    return [
      coords.lattitude < 0 ? 'S' : 'N',
      coords.lattitude.toFixed(5).replace(/0*$/, ''),
      coords.longitude > 0 ? 'E' : 'W',
      coords.longitude.toFixed(5).replace(/0*$/, ''),
    ].join(' ');
  }

  shortenText(text: string, length = 25) {
    return this.textService.shortenText(text, length);
  }
}
