import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest, timer } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Announcement } from '~app/models/announcement';
import { AnnouncementService } from '~app/services/announcement.service';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';

@Component({
  selector: 'ciao-announcement-bar',
  templateUrl: './announcement-bar.component.html',
  styleUrls: ['./announcement-bar.component.less'],
})
export class AnnouncementBarComponent implements OnInit {
  unseenAnnouncements$: Observable<Announcement[]> =
    this.announcementService.UnseenAnnouncements$;
  announcementsResult$: Observable<Announcement[]> =
    this.announcementService.Announcements$;
  announcement$: Observable<Announcement>;
  showAnnouncements = true;
  showTab = false;

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  constructor(private announcementService: AnnouncementService) {}

  ngOnInit(): void {
    this.startTimer();
  }

  startTimer() {
    this.announcement$ = combineLatest([
      this.unseenAnnouncements$,
      timer(0, 5000),
    ]).pipe(
      filter(([ann, c]) => !!ann),
      map(([announcements, count]) => {
        this.showTab = announcements.length > 0;
        let index = count % announcements.length;
        return announcements[index];
      })
    );
  }

  openAnnouncementBar() {
    this.startTimer();
    this.showAnnouncements = true;
  }

  closeAnnouncementBar() {
    this.showAnnouncements = false;
  }

  //currently not going to be used but might be useful later so leaving it
  dismissAnnouncement(announcement) {
    this.announcementService.Dismiss(announcement);
  }
}
