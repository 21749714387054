import { Component, Input, SimpleChanges } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormRecord,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { SelectOption } from '../field/types';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ciao-form-checkbox-group',
  templateUrl: './form-checkbox-group.component.html',
  styleUrl: './form-checkbox-group.component.less',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FormCheckboxGroupComponent,
    },
  ],
})
export class FormCheckboxGroupComponent
  extends NgControl
  implements ControlValueAccessor
{
  control = new FormRecord<FormControl<boolean>>({});
  /** Checkbox group input for defining the label of the checkbox group */
  @Input() label: string;
  /** Checkbox group input for defining the data to use to populate the checkboxes */
  @Input() options: SelectOption[];
  /** Checkbox group input for defining wether or not the "select all..." button should be shown*/
  @Input() selectAllEnabled: boolean = false;

  subscriptions = new Subscription();

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options) {
      this.resetOptions();
    }
  }

  resetOptions() {
    let options = this.options || [];
    Object.keys(this.control.controls).forEach((key) =>
      this.control.removeControl(key)
    );
    options.forEach((option) => {
      let singleControl = new FormControl(false);
      if (option.disabled) {
        singleControl.disable();
      }
      this.control.addControl(option.value, singleControl);
    });
  }

  viewToModelUpdate(newValue: any): void {
    throw new Error('Method not implemented.');
  }

  /**
   * Input from form: ['id1', 'id2', 'id_etc'].
   * Output to internal: {'id1': true, 'id2': true, 'id3': false (and so on)}
   */
  writeValue(obj: string[]): void {
    if (!obj) {
      obj = [];
    }
    // Make sure that the value being set has a default for all entries
    // Clears a console error and a potential freezing bug
    let value = Object.fromEntries(
      Object.entries(this.control.controls).map(([key, value]) => [
        key,
        obj.includes(key),
      ])
    );
    this.control.setValue(value);
  }
  registerOnChange(fn: any): void {
    this.subscriptions.add(
      this.control.valueChanges
        .pipe(
          map((internalValue) => {
            return Object.entries(internalValue)
              .filter(([key, value]) => value)
              .map(([key, value]) => key);
          }),
          tap(fn)
        )
        .subscribe()
    );
  }
  registerOnTouched(fn: any): void {
    this.subscriptions.add(
      this.control.statusChanges
        .pipe(
          map((_) => this.control.touched),
          distinctUntilChanged(),
          tap(fn)
        )
        .subscribe()
    );
  }

  selectAll() {
    this.options.forEach((option) => {
      this.control.controls[option.value].setValue(true);
    });
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
      this.options?.forEach((option) => {
        if (option.disabled) {
          this.control.controls[option.value].disable();
        }
      });
    }
  }
}
