import { Injectable } from '@angular/core';
import {
  IsLoadingPaginationResult,
  PaginationData,
  PaginationResult,
  PersonAttributes,
  UserAttributes,
} from '~models';
import { ApiService, Endpoint } from './api.service';
import { Observable, BehaviorSubject, AsyncSubject, combineLatest } from 'rxjs';
import {
  catchError,
  filter,
  map,
  startWith,
  switchMap,
  switchMapTo,
  take,
  tap,
} from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import * as _ from 'lodash-es';
import { UserGroupService } from './user-group.service';
import { RolePermissionService } from './role-permission.service';
import { NotificationNumber, PersonContactInfo } from '~app/models/contactInfo';
import { AuthService } from './auth.service';
import { PersonAttributesSlim } from '~app/models/person';

@Injectable({
  providedIn: 'root',
})
export class PersonService {
  private dropdownEndpointUrl = '/people/all-person-dropdown-list';
  private endpoint = this.apiService.endpoint<PersonAttributes>('people');
  /** Call `personService.refresh$.next()` to reload the People Table search endpoint */
  public readonly refresh$ = new BehaviorSubject<void>(null);
  private _allPeople = new BehaviorSubject<PersonAttributesSlim[]>([]);
  public readonly allPeople = this._allPeople.asObservable().pipe(
    map(_.clone),
    map((people) =>
      people.sort((a, b) => {
        let aN = a.displayName.toLowerCase();
        let bN = b.displayName.toLowerCase();
        return aN > bN ? 1 : aN < bN ? -1 : 0;
      })
    )
  );
  private _peopleById: { [personId: string]: PersonAttributesSlim } = {};

  constructor(
    private apiService: ApiService,
    private userGroupService: UserGroupService,
    private rolePermissionService: RolePermissionService,
    private authService: AuthService
  ) {
    this.endpoint;
    this.allPeople.subscribe((people) => {
      people.forEach((person) => {
        this._peopleById[person.id] = person;
      });
    });
    this.loadAllPeople();
  }

  private _updatePerson(person: PersonAttributes) {
    let allPeople = this._allPeople.getValue();
    let oldPerson = allPeople.find((item) => item.id === person.id);
    if (oldPerson) {
      Object.assign(oldPerson, person);
    } else {
      allPeople.push(person);
    }
    // save group on person object when we come back
    this._allPeople.next(allPeople);
  }

  findById(id: string): Observable<PersonAttributes> {
    return this.endpoint
      .Get(id)
      .pipe(tap((person) => this._updatePerson(person)));
  }

  /** Get a person's contact information */
  getContactInfo(personId: string) {
    return this.apiService.GetSingle<PersonContactInfo>(
      'people/contact-info/' + personId
    );
  }

  saveMyContactInfo(input, personId: string) {
    let apiRequest$: Observable<{
      governmentPhone: NotificationNumber;
      personalPhone: NotificationNumber;
    }>;

    if (personId) {
      apiRequest$ = this.apiService.Put('people', 'my-contact-info', input);

      return apiRequest$.pipe(
        catchError((err, caught) => {
          this.loadAllPeople();
          this.refresh$.next();
          throw err;
        })
      );
    }
  }

  search(paginationData: Partial<PaginationData>) {
    let fullPaginationData: PaginationData = {
      limit: paginationData.limit ?? 25,
      offset: paginationData.offset ?? 0,
      order: paginationData.order ?? [['displayName', 'ASC']],
      where: paginationData.where ?? [],
    };
    let search = this.refresh$.pipe(
      switchMapTo(
        this.apiService
          .Search<PaginationResult<PersonAttributes>>(
            '/people',
            fullPaginationData
          )
          .pipe(startWith(IsLoadingPaginationResult<PersonAttributes>()))
      )
    );
    return combineLatest([
      search,
      this.userGroupService.allUserGroupsById$,
      this.rolePermissionService.RolesById$,
    ]).pipe(
      map(([searchResults, userGroups, roles]) => {
        searchResults.rows?.forEach((person) => {
          person.AssignedRoles.forEach((aRole) => {
            aRole.userGroup = userGroups[aRole.UserGroupId];
            aRole.role = roles[aRole.RoleId];
          });
          person.AssignedRoles.sort((a, b) => {
            return this.userGroupService.compareForSort(
              a.userGroup,
              b.userGroup
            );
          });
        });
        return searchResults;
      })
    );
  }

  loadAllPeople() {
    let returnValue = new AsyncSubject<PersonAttributesSlim[]>();
    let peopleObs = this.authService.oAuthInfo$.pipe(
      filter((oAuthInfo) => !!oAuthInfo?.email),
      take(1),
      switchMapTo(
        this.apiService.GetAll<PersonAttributesSlim>(this.dropdownEndpointUrl)
      )
    );

    peopleObs.subscribe((allPeople: PersonAttributes[]) => {
      allPeople.forEach((person) => {
        this.userGroupService
          .findById(person.mainGroupId)
          .pipe(map((userGroup) => (person.mainGroup = userGroup)))
          .subscribe();
      });

      this._allPeople.next(allPeople);
      returnValue.next(allPeople);
      returnValue.complete();
    });
    return returnValue;
  }
  /**
   * Add Or Update Person
   * If Adding a Person, they must have emails.
   */
  savePerson(
    input: PersonAttributes,
    users?: Partial<UserAttributes>[]
  ): Observable<PersonAttributes> {
    // set main group ID equal to newly selected group ID
    input.mainGroupId = input.mainGroup?.id ?? input.mainGroupId;

    let apiRequest$: Observable<PersonAttributes>;
    if (input.id) {
      // Existing Person
      apiRequest$ = this.endpoint
        .Put(input.id, input)
        .pipe(
          users
            ? switchMap((savedPerson: PersonAttributes) =>
                this.setEmails(savedPerson.id, users)
              )
            : tap(() => {})
        );
    } else {
      // New Person
      input.users = users.map((user) => ({
        email: user.email,
        personId: undefined,
        notificationOptions: user.notificationOptions,
      }));
      apiRequest$ = this.endpoint.Post(input);
    }

    return apiRequest$.pipe(
      tap((savedPerson: PersonAttributes) => {
        this._updatePerson(savedPerson);
        this.refresh$.next();
      }),
      catchError((err, caught) => {
        this.loadAllPeople();
        this.refresh$.next();
        throw err;
      })
    );
  }
  deletePerson(id: uuid): Observable<void> {
    let request = this.endpoint.Delete(id);
    return request.pipe(
      map((attr: PersonAttributes) => {
        let allPeople = this._allPeople.getValue();
        let remainingPeople = [];
        allPeople.forEach((item) => {
          if (item.id === id || item.id === attr.id) {
            // people getting deleted
            delete this._peopleById[item.id];
          } else {
            remainingPeople.push(item);
          }
        });
        this._allPeople.next(remainingPeople);
        this.refresh$.next();
      })
    );
  }
  setEmails(personId: uuid, users: Partial<UserAttributes>[]) {
    let request = this.apiService.Custom<{ checkEntity: PersonAttributes }>(
      'PUT',
      `/people/${personId}/set-emails`,
      users
    );
    return request.pipe(map((response) => response.checkEntity));
  }
}
