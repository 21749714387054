import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { delay, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { TripAttributes } from '~app/models';
import { RolePermissionService } from '~app/services/role-permission.service';
import { UserGroupService } from '~app/services/user-group.service';

@Component({
  selector: 'ciao-trip-details-form',
  templateUrl: './trip-details-form.component.html',
  styleUrls: [
    './trip-details-form.component.less',
    '../trip-modal.component.less',
  ],
})
export class TripDetailsFormComponent implements OnInit {
  @Input() trip: TripAttributes;
  @Input() mode: 'create' | 'update' | 'add' = 'add';
  @Input() openTab: string;
  @Input() formValid: boolean;
  @Output() formStatusChange = new EventEmitter<boolean>();
  @Output() userGroupChange = new EventEmitter<string>();

  userGroupOptions$: Observable<{ value: string; label: string }[]> =
    this.populateUserGroupControl();
  now = new Date();
  isUpdate: boolean;
  tripDetailsFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    userGroupId: new UntypedFormControl('', Validators.required),
    startDate: new UntypedFormControl('', [
      Validators.required,
      this.startDateValidator.bind(this),
    ]),
    endDate: new UntypedFormControl('', [
      Validators.required,
      this.endDateValidator.bind(this),
    ]),
  });

  userGroupControl = this.tripDetailsFormGroup.get('userGroupId');
  startDateControl = this.tripDetailsFormGroup.get('startDate');
  endDateControl = this.tripDetailsFormGroup.get('endDate');

  private subscriptions: Subscription = new Subscription();

  constructor(
    private userGroupService: UserGroupService,
    private rolePermissionService: RolePermissionService
  ) {}

  ngOnInit(): void {
    this.setInitdata();
    this.setupFormStatusEmission();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode) {
      this.isUpdate = this.mode === 'update';
      if (this.mode === 'create') {
        this.setupUserGroupChangeEmission();
      }
      if (this.mode === 'update') {
        this.addValuesToForm();
      }

      // if we are not creating a trip we need to disable the user group field
      if (this.mode !== 'create') {
        this.tripDetailsFormGroup.get('userGroupId').disable();
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  addValuesToForm() {
    this.userGroupOptions$
      .pipe(
        take(1), // take(1) so dont have to add to subscription destruction variable
        delay(0),
        tap(() => {
          this.tripDetailsFormGroup.patchValue({
            id: this?.trip?.id,
            userGroupId: this?.trip?.userGroupId || '',
            startDate: this?.trip?.startDate || '',
            endDate: this?.trip?.endDate || '',
          });
        })
      )
      .subscribe();
  }

  public getFormData() {
    return this.tripDetailsFormGroup;
  }

  populateUserGroupControl() {
    // Grab Teams first, then search within Teams,
    // so if the definition of "Team" changes again, this won't change
    return this.userGroupService.allTeams$.pipe(
      switchMap((groups) =>
        this.rolePermissionService.searchMyUserGroupsByPermission({
          permissionIds: ['create_trips', 'edit_trip'],
          filterWithinUserGroups: groups,
        })
      ),
      this.userGroupService.op_sortGroups,
      map((userGroups) =>
        userGroups.map((userGroup) => ({
          value: userGroup.id,
          label: `${userGroup.labelPrefix}${userGroup.label}`,
        }))
      ),
      shareReplay(1)
    );
  }

  setInitdata() {
    if (this.mode === 'create') {
      this.tripDetailsFormGroup.setValue({
        id: '',
        userGroupId: '',
        startDate: new Date().toISOString(),
        endDate: '',
      });
    }
  }

  setupFormStatusEmission() {
    let formStatusSub = this.tripDetailsFormGroup.statusChanges.subscribe(
      (status) => {
        this.formStatusChange.emit(status === 'VALID');
      }
    );

    this.subscriptions.add(formStatusSub);
  }

  setupUserGroupChangeEmission() {
    let userGroupSub =
      this.tripDetailsFormGroup.controls.userGroupId.valueChanges
        .pipe(
          tap((id) => {
            this.userGroupChange.emit(id);
          })
        )
        .subscribe();
    this.subscriptions.add(userGroupSub);
  }

  //#region Date Validators
  startDateValidator(control) {
    let invalid = control.value > this.endDateControl?.value;
    if (this.endDateControl?.invalid !== invalid) {
      setTimeout(() => this.endDateControl?.updateValueAndValidity(), 0);
    }
    if (invalid) {
      return {
        endDateBeforeStartDate: 'Start Date must be before End Date',
      };
    }
  }
  endDateValidator(control) {
    let invalid = control.value < this.startDateControl?.value;
    if (this.startDateControl?.invalid !== invalid) {
      setTimeout(() => this.startDateControl?.updateValueAndValidity(), 0);
    }
    if (invalid) {
      return {
        endDateBeforeStartDate: 'End Date must be after Start Date',
      };
    }
  }
  //#endregion
}
