<div class="container-fluid modal-form">
  <h3>{{ isUpdate ? "Update" : "Add" }} Crew Member</h3>

  <div class="row">
    <ciao-form-field
      class="form-field col-lg-6 col-sm-12"
      type="combobox"
      label="Crew Member"
      [formControlInput]="crewMemberForm.get('crewMember')"
      [selectOptions$]="crewMemberOptions$"
      [compareWith]="comparePersonForSelect"
    >
    </ciao-form-field>

    <ciao-form-field
      class="member-info col-lg-6 col-sm-12"
      subtype="fsCellPhones"
      label="Cell Phone"
      type="phoneNumber"
      [formControlInput]="crewMemberForm.get('fsCellPhone')"
      placeholder="Crew Member Cell Phone"
    ></ciao-form-field>
  </div>

  <div class="row">
    <ciao-form-field
      class="form-field col-lg-6 col-sm-12"
      type="combobox"
      label="Supervisor"
      [toolTip]="
        'A Supervisor cannot be a Crew Member on the Trip and will initiate safety protocols in the event of an emergency. A Supervisor may be a dispatcher, a lead, or safety manager who has been designated by Admin.'
      "
      [formControlInput]="crewMemberForm.get('supervisor')"
      [selectOptions$]="supervisorOptions$"
      [compareWith]="comparePersonForSelect"
    >
    </ciao-form-field>

    <ciao-form-field
      class="member-info col-lg-6 col-sm-12"
      subtype="fsCellPhones"
      label="Supervisor Cell Phone"
      type="phoneNumber"
      [formControlInput]="crewMemberForm.get('supervisorPhone')"
      placeholder="Supervisor Cell Phone"
    ></ciao-form-field>
  </div>

  <div class="text-alert-accordion">
    <button class="text-alert-title" (click)="helpTextActive = !helpTextActive">
      <fa-icon
        [icon]="helpTextActive ? faChevronDown : faChevronRight"
      ></fa-icon>
      About Text Alerts
    </button>
    <span [ngClass]="{ active: helpTextActive }" class="text-alert-info"
      >Crew Members and Team Supervisors can choose to Receive Text
      Notifications by opting-in on their User Profile. Users will only receive
      alerts at the cell number entered in the profile. Text alerts will be sent
      2 minutes after the Trip end time, if the Trip has not been Checked-In.
    </span>
  </div>

  <div class="row">
    <ciao-form-field
      class="col-lg-6 col-sm-12"
      subtype="fsCellPhones"
      label="Satellite Phone"
      type="satPhoneNumber"
      [formControlInput]="crewMemberForm.get('satPhone')"
      placeholder="15 Digit Number"
    ></ciao-form-field>

    <ciao-form-field
      class="col-lg-6 col-sm-12"
      label="Satellite Communicator"
      type="input"
      [toolTip]="
        'This field is not required but highly suggested if details are known for a Spot, Garmin and/or other GPS tracking device.'
      "
      [formControlInput]="crewMemberForm.get('satLocatorId')"
      placeholder="Tracker Device"
    ></ciao-form-field>
  </div>

  <div class="row">
    <ciao-form-field
      label="Other Notes"
      type="textarea"
      [toolTip]="
        'Include any additional known information that will assist in establishing location or contact for crew members.'
      "
      [formControlInput]="crewMemberForm.get('notes')"
      placeholder="Add radio or other communication devices"
    ></ciao-form-field>
  </div>
</div>
