import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { map, shareReplay } from 'rxjs/operators';

import { StateAttributes, VehicleEquipment } from '~app/models';
import { LocationService } from '~app/services';
import { TripModalFormComponent } from '../types';

@Component({
  selector: 'ciao-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.less'],
})
export class EquipmentFormComponent implements OnInit, TripModalFormComponent {
  @Input() editingExisting = false;
  formGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    details: new UntypedFormControl(''),
    state: new UntypedFormControl(''),
    license: new UntypedFormControl(''),
    make: new UntypedFormControl(''),
    model: new UntypedFormControl(''),
  });
  get finalResult(): VehicleEquipment {
    return this.formGroup.value;
  }
  stateOptions$ = this.locationService.allStates$.pipe(
    map((states) =>
      states.map((state) => ({
        value: {
          id: state.id,
          stateCode: state.stateCode,
          stateName: state.stateName,
        },
        label: state.stateCode,
      }))
    ),
    shareReplay(1)
  );

  constructor(private locationService: LocationService) {}

  compareStateForSelect(
    state1: StateAttributes,
    state2: StateAttributes
  ): boolean {
    return state1 && state2 && state1.id === state2.id;
  }

  ngOnInit(): void {}
}
