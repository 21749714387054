import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { CiaoModalComponent } from '~app/components/shared/ciao-modal/ciao-modal.component';

import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen';
import { faShapes as icon } from '@fortawesome/free-solid-svg-icons/faShapes';

import { TripAttributes } from '~app/models';
import { TripDetailsFormComponent } from './trip-details-form/trip-details-form.component';
import { TripCrewFormComponent } from './trip-crew-form/trip-crew-form.component';
import { TripLocationsFormComponent } from './trip-locations-form/trip-locations-form.component';
import { TripEquipmentFormComponent } from './trip-equipment-form/trip-equipment-form.component';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TripService } from '~app/services';
import { catchError, map, tap } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { Router } from '@angular/router';
import { UserGroupService } from '~app/services/user-group.service';
import { OkayAttributes } from '~app/models/okay';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ciao-trip-modal',
  templateUrl: './trip-modal.component.html',
  styleUrls: ['./trip-modal.component.less'],
})
export class TripModalComponent implements OnInit {
  @Input() trip: TripAttributes;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter();
  @Output() onTripSave: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('tripModal') tripModal: CiaoModalComponent;
  @ViewChild('detailsForm') detailsForm: TripDetailsFormComponent;
  @ViewChild('crewForm') crewForm: TripCrewFormComponent;
  @ViewChild('locationForm') locationForm: TripLocationsFormComponent;
  @ViewChild('equipmentForm') equipmentForm: TripEquipmentFormComponent;
  @ViewChild('detailsForm, crewForm, locationForm, equipmentForm')
  myCurrentForm:
    | TripDetailsFormComponent
    | TripCrewFormComponent
    | TripLocationsFormComponent
    | TripEquipmentFormComponent;
  @ViewChild('cancelCreateTripModal') cancelCreateTripModal: CiaoModalComponent;

  mode: 'create' | 'update' | 'add' = 'create';
  userGroupId: string;
  dataId: string = null;
  formValid: boolean = false;
  tripValid: boolean = true;
  isSaved: boolean = false;
  currentlySaving: boolean = false;
  activeLabelText = '';

  faTriangle = icon;
  faPen = faPen;
  faArrowRight = faChevronRight;
  faArrowLeft = faChevronLeft;

  private readonly subscriptions: Subscription = new Subscription();

  tabs = {
    details: {
      display: 'Details',
      name: 'details',
      disabled: false,
      activeLabelText: '',
      getComponent: () => this.detailsForm,
    },
    locations: {
      display: 'Locations',
      name: 'locations',
      disabled: false,
      activeLabelText: '+ Add Another Location',
      getComponent: () => this.locationForm,
    },
    crewMembers: {
      display: 'Crew',
      name: 'crewMembers',
      disabled: false,
      activeLabelText: '+ Add Another Crew Member',
      getComponent: () => this.crewForm,
    },
    equipment: {
      display: 'Equipment',
      name: 'equipment',
      disabled: false,
      activeLabelText: '+ Add More Equipment',
      getComponent: () => this.equipmentForm,
    },
  };

  openTab: keyof typeof this.tabs | '' = 'details';

  tabsDisplay = [
    this.tabs.details,
    this.tabs.locations,
    this.tabs.crewMembers,
    this.tabs.equipment,
  ];

  sendingTrip = false;
  errorMessage: string = '';
  lastAction: '' | 'added' | 'created' | 'deleted' | 'updated' = '';

  constructor(
    private toastrService: ToastrService,
    private tripService: TripService,
    private userGroupService: UserGroupService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // handle trip modal usergroup on edit page
    if (changes.trip) {
      this.userGroupId = this.trip?.userGroupId;

      if (this.trip?.id) {
        this.mode = 'update';
      }
    }
  }

  get canMoveToNextTab() {
    return this.openTab == 'equipment' || !this.formValid;
  }

  changeTab(nextTab: string) {
    if (!Object.keys(this.tabs).includes(nextTab)) {
      throw new Error('Bad tab passed to changeTab(nextTab)');
    }

    let currentForm: UntypedFormGroup;
    currentForm = this.getFormData();
    // dont let user change tabs if form is dirty and not disabled
    if (currentForm && currentForm.touched && currentForm.value.invalid) {
      // Warn user to save before navigating to next tab
      this.toastrService.warning(
        'You have unsaved data. Select the Save button before moving to a different section of the form.',
        'Warning!'
      );
      return;
    } else {
      this.openTab = nextTab as keyof typeof this.tabs;
      let isValid = this.tabs?.[this.openTab]
        ?.getComponent()
        ?.getFormData().valid;
      this.formValid = isValid;
      this.isSaved = false;
      this.activeLabelText = this?.tabs?.[nextTab]?.activeLabelText || '';
    }
    this.setTabsDisabled();
  }

  clearForm() {
    const formData = this.getFormData();
    formData.reset();
    this.isSaved = false;
    this.formValid = false;
    this.mode = 'add';
    this.dataId = null;
    formData.enable();
    this.setTabsDisabled();
  }

  deleteItemFromList(itemList, itemToDelete) {
    itemList = itemList.filter((element) => {
      return element.id !== itemToDelete.id;
    });
    return itemList;
  }

  determineTabState(tab) {
    return this.mode === 'create' || this.openTab === tab;
  }

  getFormData() {
    return this.myCurrentForm?.getFormData() ?? null;
  }

  get getAlternateCloseState() {
    return this.mode === 'create';
  }

  handleCancelTrip() {
    this.cancelCreateTripModal.openSmallModal();
  }

  handleCreateTrip(checkout: boolean) {
    let tripData: any = {
      details: this.detailsForm.getFormData().value,
      locations: this.locationForm.getFormData().value,
      crew: this.crewForm.getFormData().value,
      equipment: this.equipmentForm.getFormData().value,
    };

    // TODO: This is async but this.trip assignment below is sycnhronous.Need to fix
    let userGroup;
    this.userGroupService
      .findById(tripData.details.userGroupId)
      .pipe(
        tap((value) => {
          userGroup = value;
        })
      )
      .subscribe();

    let tripEquipmetnFormHasAtLeastOneValue = Object.values(
      tripData.equipment
    ).some((val) => !!val);

    this.trip = {
      id: null,
      startDate: tripData.details.startDate,
      endDate: tripData.details.endDate,
      userGroupId: tripData.details.userGroupId,
      usergroup: userGroup,
      crewMembers: [tripData.crew],
      locations: [tripData.locations],
      okays: [],
      equipmentList: tripEquipmetnFormHasAtLeastOneValue
        ? [tripData.equipment]
        : [],
      // notes: '', //TODO: Verify this wont be added back in later
    };

    this.handleUpdateTrip('create', checkout);
  }

  handleFormStatusChange(isValid: boolean) {
    this.formValid = isValid;
    // until its determined why status out of details emits false on user group change (when there is no supervisor in crew member form)
    // this fixes the issue by accessing the details form directly and getting the correct state of the form
    if (this.openTab === 'details') {
      this.formValid = this.tabs.details?.getComponent()?.getFormData()?.valid;
    }
    this.setTabsDisabled();
  }

  handleModal(modalRef: NgbModalRef) {
    if (!modalRef) {
      this.isSaved = false;
      this.formValid = false;
      this.onClose.emit(true);
      setTimeout(() => {
        this.openTab = '';
      }, 200);
    }
  }

  handleListItemUpdate(itemFormData, updatedTripPartial, updateType?) {
    let itemList;
    let listItemToUpdate;
    if (this.openTab.toString() === 'locations') {
      itemList = updatedTripPartial.locations;
      listItemToUpdate = {
        id: itemFormData.id ?? undefined, // TODO: Fix for saving first location on trip... set back to null when backend is fixed
        county: itemFormData.county ?? null,
        countyCode: itemFormData.countyCode ?? null,
        plotNumber: itemFormData.plotNumber ?? null,
        coords: itemFormData.coords ?? null,
        textCoords: itemFormData.textCoords ?? null,
        description: itemFormData.description,
      };
    } else if (this.openTab.toString() === 'crewMembers') {
      itemList = updatedTripPartial.crewMembers;
      listItemToUpdate = itemFormData;
    } else if (this.openTab.toString() === 'equipment') {
      itemList = updatedTripPartial.equipmentList;
      listItemToUpdate = itemFormData;
    }

    if (updateType === 'delete') {
      switch (this.openTab.toString()) {
        case 'locations':
          updatedTripPartial.locations = this.deleteItemFromList(
            itemList,
            listItemToUpdate
          );
          break;
        case 'crewMembers':
          updatedTripPartial.crewMembers = this.deleteItemFromList(
            itemList,
            listItemToUpdate
          );
          break;
        case 'equipment':
          updatedTripPartial.equipmentList = this.deleteItemFromList(
            itemList,
            listItemToUpdate
          );
          break;
      }
      return updatedTripPartial;
    }
    if (listItemToUpdate.id) {
      itemList[itemList.findIndex((item) => item.id === listItemToUpdate.id)] =
        listItemToUpdate;
    } else if (this.mode === 'update' && !listItemToUpdate.id) {
      if (itemList.length > 0) {
        itemList.pop();
        itemList.push(listItemToUpdate);
      }
    } else {
      itemList.push(listItemToUpdate);
    }
    return updatedTripPartial;
  }

  handleListItemDelete() {
    try {
      this.handleUpdateTrip('delete');
      this.getFormData().markAsUntouched();
      this.getFormData().disable();
    } catch (err) {
      throw `There was an error deleting the item. Error: ${err}`;
    }
  }
  handleSave(ev) {
    switch (this.mode) {
      case 'add':
        this.lastAction = 'added';
        break;
      case 'create':
        this.lastAction = 'created';
        break;
      case 'update':
        this.lastAction = 'updated';
        break;
    }
    try {
      this.handleUpdateTrip('save');
      this.getFormData().markAsUntouched();
      this.getFormData().disable();

      // if we are adding a new item keep track of save so we can reenable fields
      if (this.mode === 'add') {
        this.isSaved = true;
      }
    } catch (err) {
      throw `There was an error saving the trip. Error: ${err}`;
    }
  }

  handleUpdateTrip(updateType: string, checkout?) {
    let updatedTripPartial: TripAttributes = this.trip;
    if (updateType !== 'create') {
      let tableToBeUpdated = this.openTab;
      let singleFormGroupData = this.getFormData().value;
      switch (tableToBeUpdated) {
        case 'details':
          updatedTripPartial = singleFormGroupData;
          break;
        case 'locations':
        case 'crewMembers':
        case 'equipment':
          updatedTripPartial = this.handleListItemUpdate(
            singleFormGroupData,
            updatedTripPartial,
            updateType
          );
          break;
      }
      this.lastAction = 'updated';
    } else {
      this.lastAction = 'created';
    }

    this.currentlySaving = true;
    let tripSaveConfirmation = this.tripService.saveTrip(updatedTripPartial);
    tripSaveConfirmation
      .pipe(
        tap((trip) => {
          if (checkout) {
            this.SendOkay(trip);
          }
          this.router.navigate(['trip', 'id', trip.id]);
        }),
        tap(() => {
          if (this.lastAction === 'updated' || this.lastAction === 'added') {
            this.toastrService.success(
              `Trip has been updated successfully.`,
              'Success'
            );
          } else if (checkout) {
            this.toastrService.success(
              `Trip has been created and checked out of successfully. Trip status is now Open.`,
              'Success'
            );
          } else {
            this.toastrService.success(
              `Trip has been created successfully. Trip status is now Planned.`,
              'Success'
            );
          }
        }),
        tap(() => {
          if (this.mode !== 'add') {
            this.tripModal.close('');
          }
          this.currentlySaving = false;
        }),
        catchError((err) => {
          console.error(err);
          let theMessage =
            err?.error?.errors ||
            err?.errors ||
            err?.error ||
            err?.message ||
            err;
          this.errorMessage = theMessage.toString();
          this.currentlySaving = false;
          if (this.lastAction === 'created') {
            this.toastrService.error(
              `Trip was not created successfully. Error: ${this.errorMessage}`,
              'Error'
            );
          } else {
            this.toastrService.error(
              `Trip was not updated successfully. Error: ${this.errorMessage}`,
              'Error'
            );
          }
          return of(null);
        })
      )
      .subscribe();
  }

  navigateToTripList() {
    this.router.navigate(['/search']);
    this.tripModal.close('trip cancled');
    this.cancelCreateTripModal.dismiss('dismiss');
  }

  nextTab(tab) {
    switch (tab) {
      case 'details':
        this.changeTab('locations');
        break;
      case 'locations':
        this.changeTab('crewMembers');
        break;
      case 'crewMembers':
        this.changeTab('equipment');
        break;
    }
  }

  openModal(
    id: string | null,
    tabName: keyof typeof this.tabs,
    modeOverride?: 'create' | 'add' | 'update'
  ) {
    this.mode = modeOverride;
    this.dataId = id;
    this.changeTab(tabName);
    this.tripModal.openModal();
  }

  previousTab(tab) {
    switch (tab) {
      case 'equipment':
        this.changeTab('crewMembers');
        break;
      case 'crewMembers':
        this.changeTab('locations');
        break;
      case 'locations':
        this.changeTab('details');
        break;
    }
  }

  refreshTrip() {
    this.onTripSave.emit(true);
  }

  returnToEdit() {
    this.isSaved = false;
    this.mode = 'update';
    const formData = this.getFormData();
    formData.enable();
  }

  resetTripAfterModalClose() {
    // refresh after trip modal close so new item is available to update
    this.refreshTrip();
    this.openTab = '';
  }

  SendOkay(trip) {
    let okay: OkayAttributes = {
      tripId: trip.id,
      type: 'checkOut',
      timeOkay: trip.startDate,
      notes: 'Trip created and checked out of immediately',
    };
    let okayConfirmation = this.tripService.addOkay(okay);
    okayConfirmation.subscribe({
      complete: () => {},
      error: (err) => {
        console.error('there was an error checking out');
        this.toastrService.error('There was an error checking out.', 'Error');
      },
    });
  }

  setTabsDisabled() {
    const activeTabToUpdate = this.openTab;
    Object.keys(this.tabs).forEach((tabName) => {
      const isUpdate = this.mode === 'update';
      const openTab = tabName === activeTabToUpdate;
      const isDisabled = !openTab;
      // on create details is always enabled
      // subsequent tabs become enabled when previous tabs are all valid
      if (this.mode !== 'create') {
        // by default disable all tabs that are not the open one except on create
        if (tabName !== activeTabToUpdate) {
          this.tabs[tabName].disabled = isDisabled;
        }
        // never let user edit the details tab if they are updating a different tab
        if (!isUpdate && tabName == 'details') {
          this.tabs.details.disabled = true;
        }
      } else {
        // details is always enabled
        if (tabName === 'details') {
          this.tabs[tabName].disabled = false;
        }

        //disable all tabs after current tab if isFormValid = false
        if (!this.formValid) {
          switch (this.openTab) {
            case 'details':
              this.tabs.locations.disabled = true;
              this.tabs.crewMembers.disabled = true;
              this.tabs.equipment.disabled = true;
              break;
            case 'locations':
              this.tabs.crewMembers.disabled = true;
              this.tabs.equipment.disabled = true;
              break;
            case 'crewMembers':
              this.tabs.equipment.disabled = true;
          }
        } else {
          switch (this.openTab) {
            case 'details':
              this.tabs.locations.disabled = false;
              break;
            case 'locations':
              this.tabs.crewMembers.disabled = false;
              break;
            case 'crewMembers':
              this.tabs.equipment.disabled = false;
              break;
          }
        }
        // timeout to get past component init issues. Enable create button
        setTimeout(() => {
          if (
            this.tabs?.details?.getComponent()?.getFormData().valid &&
            this.tabs?.locations?.getComponent()?.getFormData().valid &&
            this.tabs?.crewMembers?.getComponent()?.getFormData().valid &&
            this.tabs?.equipment?.getComponent()?.getFormData().valid
          ) {
            this.tripValid = true;
          } else {
            this.tripValid = false;
          }
        }, 0);
      }
    });
  }

  updateUserGroupId(id) {
    this.userGroupId = id;
    // since we do not know if the supervisor/crewMember will be in the new list we must clear the fields
    if (this.mode === 'create') {
      this.tabs.crewMembers?.getComponent()?.clearCrewAndSupervisorFormFields();
      this.tabs.equipment.disabled = true;
    }
  }
}
