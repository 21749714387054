<div class="trip-content">
  <ciao-trip-modal
    #tripModal
    [trip]="trip$ | async"
    (onTripSave)="refreshTrip()"
  ></ciao-trip-modal>
  <ng-container *ngIf="trip$ | async as trip">
    <div class="trip-header">
      <p id="note">
        Note: Trips will be removed 30 days after the Expected Return
      </p>
    </div>

    <div id="summary">
      <div id="update" class="col-lg-4 col-sm-12">
        <h2 class="title">Trip Record</h2>

        <div class="summary-content">
          <div class="quick-btns section">
            <div class="btns">
              <ciao-okay-modal
                [disabled]="
                  ['Closed', 'Open', 'Late'].includes(trip?.tripStatus)
                "
                okayType="checkOut"
                (onSave)="onSendOkay()"
                class="btn"
                buttonClass="btn-action-solid"
                [trip]="trip"
              ></ciao-okay-modal>
              <ciao-okay-modal
                [disabled]="['Closed', 'Planned'].includes(trip?.tripStatus)"
                okayType="okay"
                (onSave)="onSendOkay()"
                class="btn"
                buttonClass="btn-action-solid"
                [trip]="trip"
              ></ciao-okay-modal>
              <ciao-okay-modal
                [disabled]="['Closed', 'Planned'].includes(trip?.tripStatus)"
                okayType="checkIn"
                (onSave)="onSendOkay()"
                class="btn"
                buttonClass="btn-action-solid"
                [trip]="trip"
              ></ciao-okay-modal>
            </div>
          </div>

          <div id="last-action" class="col-lg-7 col-sm-5" tabindex="0">
            <p class="data title">Last Update</p>
            <p class="data">
              <span> Type: </span>
              {{ getReadableOkayType(trip?.lastOkay?.type) }}
            </p>
            <p class="data">
              <span> Recorded by: </span>
              {{ trip?.lastOkay?.recordedBy?.person?.displayName }}
            </p>
            <p class="data">
              <span> Time/Date: </span>
              {{ formatDate(trip?.lastOkay?.recordedAt) }}
            </p>
            <p *ngIf="trip?.lastOkay?.notes" class="data">
              <span> Notes: </span>
              {{ shortenText(trip?.lastOkay?.notes) }}
            </p>
          </div>
        </div>
      </div>

      <div id="trip-info" class="col-lg-8 col-sm-12">
        <h2 class="title">Trip Log</h2>

        <div class="summary-content">
          <div class="table-container">
            <table id="trip-log">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Recorded By</th>
                  <th>Actual Time/Date</th>
                  <th>Entered Time/Date</th>
                  <th>Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let okay of trip?.okays" tabindex="0">
                  <td>
                    {{ getReadableOkayType(okay?.type) }}
                  </td>
                  <td>{{ okay?.recordedBy?.person?.displayName }}</td>
                  <td>{{ formatDate(okay?.recordedAt) }}</td>
                  <td>{{ formatDate(okay?.createdAt) }}</td>
                  <td>
                    {{ okay?.notes || "" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div id="record-info">
      <div id="trip-details" class="record-section">
        <div class="table-header">
          <h3 class="title">Trip Details</h3>
        </div>

        <table *ngIf="true">
          <thead>
            <tr>
              <th>Status</th>
              <th></th>
              <th>Team Name</th>
              <th>Start Date/Time</th>
              <th>Return Date/Time</th>
            </tr>
          </thead>
          <tbody>
            <tr tabindex="0" (click)="openModal(null, 'details', 'update')">
              <td class="fit-width">
                <div
                  class="trip-status status-border status-{{
                    trip?.tripStatus
                  }}"
                >
                  {{ trip?.tripStatus }}
                </div>
              </td>
              <td class="icon fit-width no-padding align-right">
                <fa-icon [icon]="faPen" class="no-padding"></fa-icon>
              </td>
              <td>
                {{ trip?.usergroup?.labelPrefix }}
                {{ trip?.usergroup?.label }}
              </td>
              <td>{{ formatDate(trip?.startDate) }}</td>
              <td>{{ formatDate(trip?.endDate) }}</td>
            </tr>
          </tbody>
        </table>
        <hr />
      </div>

      <div id="trip-locations" class="record-section">
        <mat-expansion-panel
          [expanded]="trip?.locations?.length > 0"
          [disabled]="trip?.locations?.length === 0"
        >
          <mat-expansion-panel-header class="table-header">
            <div class="table-header">
              <h3 class="title">Add Locations</h3>
            </div>
          </mat-expansion-panel-header>

          <ng-container matExpansionPanelContent>
            <table *ngIf="trip?.locations?.length > 0">
              <thead>
                <tr>
                  <th>Description</th>
                  <th>State</th>
                  <th>County</th>
                  <th>County Code</th>
                  <th>Plot Number</th>
                  <th>LAT/LONG or GPS Coordinates</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let loc of trip?.locations"
                  tabindex="0"
                  (click)="openModal(loc.id, 'locations', 'update')"
                >
                  <td class="fit-width">
                    <fa-icon class="icon" [icon]="faPen"></fa-icon>
                    {{ shortenText(loc?.description) }}
                  </td>
                  <td>{{ loc?.county?.state?.stateCode }}</td>
                  <td>{{ loc?.county?.countyName }}</td>
                  <td>{{ loc?.county?.id }}</td>
                  <td>{{ loc?.plotNumber }}</td>
                  <!-- <td>{{ displayCoords(loc?.coords) }}</td> -->
                  <td>{{ loc?.textCoords }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </mat-expansion-panel>

        <button
          class="btn-action-light"
          (click)="openModal(null, 'locations', 'add')"
        >
          + Add Location
        </button>
        <hr />
      </div>

      <div id="trip-crew" class="record-section">
        <mat-expansion-panel
          [expanded]="trip?.crewMembers?.length > 0"
          [disabled]="trip?.crewMembers?.length === 0"
        >
          <mat-expansion-panel-header class="table-header">
            <h3 class="title">Add Crew Members</h3>
          </mat-expansion-panel-header>

          <ng-container matExpansionPanelContent>
            <table *ngIf="trip?.crewMembers?.length > 0">
              <thead>
                <tr>
                  <th>Crew Members</th>
                  <th>Cell Phone</th>
                  <th>Supervisor</th>
                  <th>Supervisor Cell Phone</th>
                  <th>Satellite Phone</th>
                  <th>Satellite Communicator</th>
                  <th>Other Notes</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let c of trip?.crewMembers"
                  tabindex="0"
                  (click)="openModal(c.id, 'crewMembers', 'update')"
                >
                  <td>
                    <fa-icon class="icon" [icon]="faPen"></fa-icon>
                    {{ c?.crewMember?.displayName || "" }}
                  </td>
                  <td>{{ c?.fsCellPhone }}</td>
                  <td>{{ c?.supervisor?.displayName }}</td>
                  <td>{{ c?.supervisorPhone }}</td>
                  <td>{{ c?.satPhone }}</td>
                  <td>{{ c?.satLocatorId }}</td>
                  <td>{{ c?.notes }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </mat-expansion-panel>

        <button
          class="btn-action-light"
          (click)="openModal(null, 'crewMembers', 'add')"
        >
          + Add Crew Member
        </button>
        <hr />
      </div>

      <div id="trip-equipment" class="record-section">
        <mat-expansion-panel
          [expanded]="trip?.equipmentList?.length > 0"
          [disabled]="trip?.equipmentList?.length === 0 || !trip?.equipmentList?.[0]?.make"
        >
          <mat-expansion-panel-header class="table-header">
            <h3 class="title">Add Equipment</h3>
          </mat-expansion-panel-header>
          <ng-container matExpansionPanelContent>
            <table *ngIf="trip?.equipmentList?.length > 0">
              <thead>
                <tr>
                  <th>Vehicles/Equipment</th>
                  <th>State</th>
                  <th>License #</th>
                  <th>Make</th>
                  <th>Model</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let vehicle of trip.equipmentList; let i = index"
                  tabindex="0"
                  (click)="openModal(vehicle.id, 'equipment', 'update')"
                >
                  <td>
                    <fa-icon class="icon" [icon]="faPen"></fa-icon>
                    {{ shortenText(vehicle?.details) }}
                  </td>
                  <td>{{ vehicle?.state?.stateName }}</td>
                  <td>{{ vehicle?.license }}</td>
                  <td>{{ vehicle?.make }}</td>
                  <td>{{ vehicle?.model }}</td>
                </tr>
              </tbody>
            </table>
          </ng-container>
        </mat-expansion-panel>
        <button
          class="btn-action-light"
          (click)="openModal(null, 'equipment', 'add')"
        >
          + Add Equipment
        </button>
      </div>
    </div>

    <div id="trip-footer">
      <button class="btn-action-solid" (click)="deleteTripClicked($event)">
        Delete Trip
      </button>

      <a routerLink="summary">
        <div id="see-summary" tabindex="0">
          <h4>View Summary</h4>
          <fa-icon class="icon" [icon]="faCircle"></fa-icon>
        </div>
      </a>
    </div>

    <hr />
  </ng-container>
</div>

<!-- Delete Trip Modal -->
<ciao-modal #deleteTripConfirmModal title="Confirm Delete Trip?">
  <span modalBody>
    <div class="container">
      <div class="row">
        <h5>Are you sure you want to delete this trip?</h5>
      </div>
    </div>
  </span>
  <span modalFooter>
    <div class="d-flex">
      <button
        class="btn-action-light center"
        (click)="cancelDeleteTripClicked($event)"
      >
        Cancel
      </button>

      <button
        class="btn-action-solid center"
        (click)="confirmDeleteTripClicked($event)"
      >
        Delete Trip
      </button>
    </div>
  </span>
</ciao-modal>
