import { ResourceAttributes } from './resource';
import { UserAttributes } from './user';

export type OkayType = 'checkOut' | 'checkIn' | 'okay';

export interface OkayAttributes extends ResourceAttributes {
  type?: OkayType;
  recordedById?: string;
  recordedBy?: UserAttributes;
  recordedAt?: Date;
  timeOkay?: Date;
  satLocatorId?: string;
  tripId?: string;
  notes?: string;
  crewMembersSelected?: {
    id: string;
    firstName?: string;
    displayName?: string;
    lastName?: string;
  }[];
  closedTrip?: boolean;
}

export class Okay implements OkayAttributes {
  id: string;
  type: OkayType;
  recordedById: string;
  recordedBy?: UserAttributes;
  recordedAt: Date;
  timeOkay: Date;
  tripId: string;
  notes: string;
  crewMembersSelected?: {
    id: string;
    firstName: string;
    displayName: string;
    lastName: string;
  }[];
  constructor(meta: OkayAttributes) {
    this.assign(meta);
  }
  assign(okay: OkayAttributes) {
    this.id = okay.id;
    this.type = okay.type || 'okay';
    this.recordedBy = okay.recordedBy;
    this.recordedById = okay.recordedById || okay.recordedBy?.id;
    this.recordedAt = new Date(okay.recordedAt);
    this.timeOkay = new Date(okay.timeOkay);
    this.tripId = okay.tripId || this.tripId || '';
    this.notes = okay.notes || '';
  }
}
