import { NgForOf, NgIf, TitleCasePipe } from '@angular/common';
import { Component, Input, inject } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { TextService } from '~app/services';

@Component({
  selector: 'ciao-field-error-list',
  standalone: true,
  imports: [NgIf, NgForOf, TitleCasePipe],
  templateUrl: './field-error-list.component.html',
  styleUrl: './field-error-list.component.less',
})
export class FieldErrorListComponent {
  @Input() control: AbstractControl;
  textService = inject(TextService);

  // Need to override because we can't just... detect when another control has been touched, and transmit it over.
  // That should become available in Angular 18.
  // https://github.com/angular/angular/issues/10887
  /** Override the untouched attribute and show anyways.  Only makes changes if set to true.  If set to false, this will do nothing. */
  @Input() forceShow?: boolean;

  showErrors(): boolean {
    let invalid = this.control?.invalid;
    let dirty = this.control?.dirty;
    let touched = this.control?.touched;
    return invalid && (touched || dirty || this.forceShow);
  }
  errorList(): string[] {
    let errors = this.control.errors;
    if (errors === null) {
      return [];
    }
    let list = [];
    for (let key in errors) {
      let err = errors[key];
      list.push(this.textService.getValidationErrorText(key, err));
    }
    return list;
  }
}
