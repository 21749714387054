<ciao-modal
  #tripModal
  [alternateCloseAction]="getAlternateCloseState"
  (modalInstance)="handleModal($event)"
  (result)="resetTripAfterModalClose()"
  (closeClicked)="handleCancelTrip()"
  [loading]="currentlySaving"
>
  <div modalNav>
    <div id="nav-tabs">
      <ul>
        <li
          *ngFor="let tab of tabsDisplay"
          tabindex="0"
          [ngClass]="{ active: tab.name === openTab, disabled: tab.disabled }"
          (click)="changeTab(tab.name)"
        >
          <div class="tab-name">
            {{ tab.display }}
          </div>

          <span *ngIf="tab.name === openTab" class="triangle"> </span>
        </li>
      </ul>
    </div>
  </div>
  <div modalBody>
    <ciao-trip-details-form
      #detailsForm
      *ngIf="determineTabState('details')"
      [hidden]="!(openTab === 'details')"
      [trip]="trip"
      [mode]="mode"
      [openTab]="openTab"
      (userGroupChange)="updateUserGroupId($event)"
      (formStatusChange)="handleFormStatusChange($event)"
    ></ciao-trip-details-form>

    <ciao-trip-locations-form
      #locationForm
      *ngIf="determineTabState('locations')"
      [hidden]="!(openTab === 'locations')"
      [trip]="trip"
      [mode]="mode"
      [locationId]="dataId"
      [openTab]="openTab"
      (formStatusChange)="handleFormStatusChange($event)"
    ></ciao-trip-locations-form>

    <ciao-trip-crew-form
      #crewForm
      *ngIf="determineTabState('crewMembers')"
      [hidden]="!(openTab === 'crewMembers')"
      [mode]="mode"
      [trip]="trip"
      [userGroupId]="userGroupId"
      [crewId]="dataId"
      [openTab]="openTab"
      (formStatusChange)="handleFormStatusChange($event)"
    ></ciao-trip-crew-form>

    <ciao-trip-equipment-form
      #equipmentForm
      *ngIf="determineTabState('equipment')"
      [hidden]="!(openTab === 'equipment')"
      [trip]="trip"
      [mode]="mode"
      [equipmentId]="dataId"
      [openTab]="openTab"
      (formStatusChange)="handleFormStatusChange($event)"
    ></ciao-trip-equipment-form>
  </div>

  <div modalFooter id="trip-modal-footer">
    <ng-container *ngIf="mode == 'create'; else editButtons">
      <button
        class="btn-action-solid large"
        [disabled]="!tripValid"
        (click)="handleCreateTrip(false)"
      >
        Save
      </button>
      <button
        class="btn-action-light large"
        [hidden]="!tripValid"
        (click)="handleCreateTrip(true)"
      >
        Check Out
      </button>

      <div class="form-navigation">
        <button
          class="btn-action-light icon borderless"
          (click)="previousTab(this.openTab)"
          [disabled]="openTab == 'details'"
        >
          <fa-icon [icon]="faArrowLeft"></fa-icon>
        </button>
        <button
          class="btn-action-light icon borderless"
          (click)="nextTab(this.openTab)"
          [disabled]="canMoveToNextTab"
        >
          <fa-icon [icon]="faArrowRight"></fa-icon>
        </button>
      </div>
    </ng-container>

    <ng-template #editButtons>
      <button
        *ngIf="!isSaved || (isSaved && !activeLabelText); else addAnother"
        (click)="handleSave($event)"
        [disabled]="!formValid"
        class="btn-action-solid large"
      >
        {{ mode === "add" ? "Save" : "Update and Save" }}
      </button>
      <button
        class="btn-action-light"
        *ngIf="mode === 'update' && openTab !== 'details'"
        (click)="handleListItemDelete()"
      >
        Delete
      </button>

      <ng-template #addAnother>
        <button (click)="clearForm()" class="btn-action-light large">
          {{ activeLabelText }}
        </button>
        <button class="btn-action-light icon" (click)="returnToEdit()">
          <fa-icon [icon]="faPen"></fa-icon>
        </button>
      </ng-template>
    </ng-template>
  </div>
</ciao-modal>

<!-- Cancel 'Create Trip' Modal -->
<ciao-modal #cancelCreateTripModal>
  <span modalBody id="cancelTripBody">
    <h4 class="modal-title">WAIT!</h4>
    <div class="container">
      <div class="row cancel-warning">
        <span>
          Your Trip has not been saved.<br />
          Would you like to cancel this Trip?
        </span>
      </div>
    </div>
  </span>
  <span modalFooter>
    <div class="cancel-btn-group">
      <button
        class="btn-action-solid center large"
        (click)="cancelCreateTripModal.dismiss('continue creating trip')"
      >
        No, Continue Creating Trip
      </button>
      <button
        class="btn-action-light borderless center"
        (click)="navigateToTripList()"
      >
        Yes, Cancel Trip
      </button>
    </div>
  </span>
</ciao-modal>
